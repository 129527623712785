import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { Seo } from 'src/components/seo';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';
import { DashboardLenderLogo } from 'src/e2e-redesign/e2e-dashboard/views/dashboard.interface';
import E2eDashboardResponseHandler from 'src/e2e-redesign/e2e-dashboard/views/e2e-dashboard-response-handler';
import {
  DashboardAppsI,
  dashboardLogic_funcAndV,
} from 'src/e2e-redesign/e2e-dashboard/views/logic/e2e-client-index.logic';
import AutoApprovalCardTable from 'src/e2e-redesign/views/LenderResponses/auto-approval-card-table';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import NewDashboardHandleNotOfferCard from 'src/e2e-redesign/views/LenderResponses/new-dashboard-handle-not-offer-card';
import { AASO } from 'src/interfaces/aaso.interfaces';
import {
  ApplicationContractStatusE,
  ApplicationSubmissionStateDto,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';
import type { Page as PageType } from 'src/types/page';
import { BREAD_LENDER_ID } from 'src/utils/bread-e2e-constants';

import DashboardIntro from '../components/dashboard-intro';
import OfferDefinitionItem from '../components/definition-list';

export const getNestedOfferDate = (app: any): string => {
  const timeCompleted = app.created_at;
  if (!timeCompleted) return 'Date Not Available'; // Handle undefined or null
  const date = new Date(timeCompleted);
  if (isNaN(date.getTime())) return 'Date Not Available'; // Handle invalid date
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledLink = styled('a')(({ theme }) => ({
  backgroundColor: '#4CAF50',
  color: 'white',
  padding: theme.spacing(1.5, 4),
  borderRadius: '2rem',
  width: '100%',
  maxWidth: '400px',
  textDecoration: 'none',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '0.875rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  cursor: 'pointer',
  border: 'none',
  '&:hover': {
    backgroundColor: '#45a049',
    textDecoration: 'none',
  },
}));

const ConfirmationPage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        {/* Main Confirmation Section */}
        <StyledPaper elevation={0}>
          <Typography variant="h5" sx={{ color: '#1a237e', mb: 2, fontWeight: 500 }}>
            Thank you for setting up your myFormPiper account!
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, color: '#666' }}>
            Your application has been successfully submitted through our system to the business where you applied.
            Please note:
          </Typography>

          <Box
            component="ul"
            sx={{
              listStyle: 'disc',
              pl: 4,
              mb: 3,
              '& li': {
                color: '#666',
                mb: 1,
                '&::marker': {
                  color: '#666',
                },
              },
            }}
          >
            <li>
              {' '}
              All payment option decisions and approval notifications will come directly from the business, not from
              FormPiper.
            </li>
            <li>
              If approved, you'll work directly with the business to use the payment option, and they will guide you
              through the next steps.
            </li>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: '#666' }}>
            Have questions?
          </Typography>
          <Typography variant="body1" sx={{ color: '#666' }}>
            Please contact the business where you submitted your application for assistance.
          </Typography>
        </StyledPaper>

        {/* Additional Financing Section */}
        {/* <StyledPaper elevation={0} sx={{ backgroundColor: '#F8F9FC' }}> */}
        {/*   <Typography variant="h6" sx={{ color: '#1a237e', mb: 2, fontWeight: 500 }}> */}
        {/*     Need Additional Financing? */}
        {/*   </Typography> */}

        {/*   <Typography variant="body1" sx={{ mb: 3, color: '#666' }}> */}
        {/*     We're committed to helping you meet all your financial needs. You could qualify for up to $35,000 through */}
        {/*     our direct-to-consumer lending partners. */}
        {/*   </Typography> */}

        {/*   <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}> */}
        {/*     Consider this option to: */}
        {/*   </Typography> */}

        {/*   <Box */}
        {/*     component="ul" */}
        {/*     sx={{ */}
        {/*       listStyle: 'disc', */}
        {/*       pl: 4, */}
        {/*       mb: 3, */}
        {/*       '& li': { */}
        {/*         color: '#666', */}
        {/*         mb: 1, */}
        {/*         '&::marker': { */}
        {/*           color: '#666', */}
        {/*         }, */}
        {/*       }, */}
        {/*     }} */}
        {/*   > */}
        {/*     <li>Cover additional expenses</li> */}
        {/*     <li>Get funding directly deposited to your account</li> */}
        {/*   </Box> */}

        {/*   <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}> */}
        {/*     <StyledLink href="https://flexxbuy.com/formpiper/" target="_blank" rel="noopener noreferrer"> */}
        {/*       APPLY NOW */}
        {/*     </StyledLink> */}
        {/*   </Box> */}

        {/*   <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#666', textAlign: 'center' }}> */}
        {/*     Note: This is a separate application from your original submission and won't affect those results. */}
        {/*   </Typography> */}
        {/* </StyledPaper> */}
      </Box>
    </Container>
  );
};

const NoSubmittedFormsPage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        {/* Main Confirmation Section */}
        <StyledPaper elevation={0}>
          <Typography variant="h5" sx={{ color: '#1a237e', mb: 2, fontWeight: 500 }}>
            Thank you for setting up your myFormPiper account!
          </Typography>
        </StyledPaper>

        {/* Additional Financing Section */}
        {/* <StyledPaper elevation={0} sx={{ backgroundColor: '#F8F9FC' }}> */}
        {/*   <Typography variant="h6" sx={{ color: '#1a237e', mb: 2, fontWeight: 500 }}> */}
        {/*     Need Additional Financing? */}
        {/*   </Typography> */}

        {/*   <Typography variant="body1" sx={{ mb: 3, color: '#666' }}> */}
        {/*     We're committed to helping you meet all your financial needs. You could qualify for up to $35,000 through */}
        {/*     our direct-to-consumer lending partners. */}
        {/*   </Typography> */}

        {/*   <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}> */}
        {/*     Consider this option to: */}
        {/*   </Typography> */}

        {/*   <Box */}
        {/*     component="ul" */}
        {/*     sx={{ */}
        {/*       listStyle: 'disc', */}
        {/*       pl: 4, */}
        {/*       mb: 3, */}
        {/*       '& li': { */}
        {/*         color: '#666', */}
        {/*         mb: 1, */}
        {/*         '&::marker': { */}
        {/*           color: '#666', */}
        {/*         }, */}
        {/*       }, */}
        {/*     }} */}
        {/*   > */}
        {/*     <li>Cover additional expenses</li> */}
        {/*     <li>Get funding directly deposited to your account</li> */}
        {/*   </Box> */}

        {/*   <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}> */}
        {/*     <StyledLink href="https://flexxbuy.com/formpiper/" target="_blank" rel="noopener noreferrer"> */}
        {/*       APPLY NOW */}
        {/*     </StyledLink> */}
        {/*   </Box> */}

        {/*   <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#666', textAlign: 'center' }}> */}
        {/*     Note: This is a separate application from your original submission and won't affect those results. */}
        {/*   </Typography> */}
        {/* </StyledPaper> */}
      </Box>
    </Container>
  );
};

export const isOfferAccepted = (app: DashboardAppsI): boolean => {
  return Boolean(
    app.offers.find((offer) => {
      if (
        offer.sub_details.contract_status == ApplicationContractStatusE.funded ||
        offer.sub_details.contract_status == ApplicationContractStatusE.signed
      )
        return offer;
    }),
  );
};

const E2eClientIndex: PageType = () => {
  const { v } = dashboardLogic_funcAndV();
  const { apps, hasLoaded, hasSubmittedForms } = v;

  const CheckmarkSVG = () => (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4CAF50" />
    </svg>
  );

  const getProperCard = (groupedOffer: GroupedOfferI, index: number) => {
    if (groupedOffer.is_offer) {
      return <E2eDashboardResponseHandler groupedOffer={groupedOffer} index={index} />;
    }
    // TODO FIX - NewNotOfferCard broken bc it's using the routing package
    if (groupedOffer.is_offer === false && !groupedOffer.is_auto) {
      return <NewDashboardHandleNotOfferCard groupedOffer={groupedOffer} />;
    }
    if (groupedOffer.is_auto) {
      return <AutoApprovalCardTable groupedOffer={groupedOffer} index={index} />;
    }
  };

  const getOffersMessage = (a: DashboardAppsI): string => {
    const offers = a.offers;
    const autoApprovals = a.autoApprovals;
    if (offers.length > 0 || autoApprovals.length > 0) {
      if (offers.length > 1 || autoApprovals.length > 1) {
        return `${offers.length + autoApprovals.length} Decisions`;
      } else if (autoApprovals.length > 0) {
        return `${offers.length + autoApprovals.length} Decision`;
      } else {
        return `${offers.length + autoApprovals.length} Decision`;
      }
    }
    return 'No Decisions';
  };

  /**
   * Integration exclusive for Bread Lender
   */
  const isBreadLender = (app: DashboardAppsI) => {
    const breadOffer = app.combinedResponses.find((groupedOffer: GroupedOfferI) => {
      return groupedOffer.lender_id === BREAD_LENDER_ID;
    });

    return breadOffer !== undefined;
  };

  const handleRedirectOrOffers = () => {
    // if (false) {
    if (apps.length > 0) {
      return (
        <Box sx={{ width: '100%' }}>
          {apps.map((app: DashboardAppsI, appIndex) => (
            <Accordion key={appIndex} sx={{ boxShadow: 'none', border: 'none', marginBottom: 2 }}>
              <ThemedCard sx={{ width: '100%' }}>
                <AccordionSummary aria-controls={`panel${appIndex}-content`} id={`panel${appIndex}-header`}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <ExpandMoreIcon sx={{ marginRight: 1 }} />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          flexGrow: 1,
                          justifyContent: { sm: 'space-between' },
                          alignItems: { xs: 'flex-start', sm: 'center' },
                          gap: 1,
                        }}
                      >
                        <Box sx={{ width: '225px' }}>
                          <Typography variant="h6" component="h2" color="primary">
                            {app.store.store_name}
                          </Typography>
                        </Box>
                        <Typography variant="h6" component="p" color="primary">
                          {getOffersMessage(app)}
                        </Typography>
                        <Typography variant="h6" component="p" color="primary">
                          {getNestedOfferDate(app)}
                        </Typography>
                        {isOfferAccepted(app) && (
                          <Box>
                            <Typography
                              variant="h6"
                              component="p"
                              style={{ color: '#60C287', display: 'flex', alignItems: 'center' }}
                            >
                              <CheckCircleIcon style={{ color: '#60C287', marginLeft: '8px' }} />
                              {isBreadLender(app) ? 'Used for Purchase' : 'Offer Accepted'}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {!isOfferAccepted(app) && (
                      <Typography
                        variant="body1"
                        component="p"
                        color="primary"
                        style={{ paddingLeft: 30, paddingTop: 10 }}
                      >
                        To finalize your transaction or for more assistance, contact your location associate.
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
              </ThemedCard>

              <AccordionDetails>
                <Grid container spacing={2}>
                  {app.combinedResponses.map((groupedOffer: GroupedOfferI, index) => (
                    <Grid xs={12} key={index}>
                      {getProperCard(groupedOffer, index)}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      );
    } else {
      if (hasSubmittedForms) {
        return ConfirmationPage();
      } else {
        return NoSubmittedFormsPage();
      }
    }
  };

  const renderOfferCard = (
    offer: LenderOffersI,
    submission: ApplicationSubmissionStateDto,
    lenderLogo: DashboardLenderLogo[],
    aaso: AASO,
  ) => {
    const isSelectedOffer = aaso?.user_selection?.selections?.find((selection) => {
      if (selection.fp_unique_id === offer.fp_unique_id) return true;
      return false;
    });
    const logo = lenderLogo.find((logo) => {
      if (logo.lender_id?.toString() == submission.lender_id?.toString()) {
        return logo;
      }
    })?.logo;
    return (
      <Grid key={offer?.fp_unique_id} xs={12} sm={6} md={4}>
        <Box
          sx={{
            marginBottom: '20px',
            border: '1px solid #E0E8F5',
            borderRadius: '15px',
            minHeight: '100%',
            padding: '15px',
            overflow: 'hidden',
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={6}>
              <SingleLogo image={{ src: logo || '/logo_full.png', alt: 'logo' }} />
            </Grid>
            <Grid xs={6} display="flex" justifyContent="flex-end">
              {isSelectedOffer && <CheckmarkSVG />}
            </Grid>
          </Grid>
          <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
            {submission.lender_response &&
              OfferDefinitionItem({
                term: 'Response',
                definition: submission.lender_response?.toUpperCase(),
              })}
            {offer?.approved_amount &&
              OfferDefinitionItem({
                term: 'Approved Amount',
                definition: `$${offer?.approved_amount}`,
              })}
          </dl>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Seo title="Dashboard" />
      <ThemedContainer>
        <DashboardIntro />

        <Grid container spacing={2}>
          {hasLoaded && handleRedirectOrOffers()}
        </Grid>
      </ThemedContainer>
    </>
  );
};

export default E2eClientIndex;
