export enum AuthRoutes {
  Login = '/',
  Enroll = 'enroll',
  ContactMe = 'contact-me',
  VerifyCode = 'verify-code',
  VerifyAddress = 'verify-address',
  CreatePassword = 'create-password',
  EnrollmentSuccess = 'enrollment-success',
  ResetPassword = 'reset-password',
  AuthResetPassword = 'auth-reset-password/:token',
  NewPasswordSuccess = 'new-password-success',
}

export enum E2ERoutes {
  LandingPage = '',
  CreateAccount = 'create-account',
  VerifyCustomer = 'verify-customer',
  SelectApplicationType = 'select-application-type',
  Welcome = 'welcome',
  PrivacyPolicy = 'privacy-policy',
  TermsAndConditions = 'terms-and-conditions',
  LenderDisclosure = 'lender-disclosure',
  PrimeApplication = 'prime-application',
  SubPrimeApplication = 'sub-prime-application',
  PrimeLenderInformationReview = 'prime-lender-information-review',
  PrimeDenied = 'prime-denied',
  PrimeApproval = 'prime-approval',
  SignContract = 'sign-contract',
  SignContractCompleted = 'sign-contract-completed',
  // Completed:
  Dashboard = 'dashboard',
  Account = 'account',
  OffersAccepted = 'offers-accepted',
  // New E2E Routes
}

export enum E2eApprovalRoutes {
  ThankYou = 'thank-you',
}
export enum NewE2ERoutes {
  LandingPage = 'welcome',
  CreateAccount = 'create-account',
  CustomerAcceptNewFpDisclosures = 'review-updated-disclosures',
  CommunicationNotices = 'communication-notices',
  OTPVerification = 'otp-verification',
  SelectApplicationType = 'select-application',
  GetStarted = 'get-started',
  ProviderDisclosures = 'provider-disclosures',
  Application = 'application',
  ProviderResponses = 'provider-responses',
  ThankYou = 'thank-you',
  // R:TODO E2E P2:R REMOVE THESE AFTER CONSOLDIATE
  MultiProviderDisclosures = 'multi-provider-disclosures',
  PrimeProviderSingleFlow = 'prime-provider-response-single-flow',
}

export enum BreadCFARoutes {
  LandingPage = 'landing',
  CreateAccount = 'create',
  CustomerAcceptNewFpDisclosures = 'review-disclosures',
  CommunicationNotices = 'notices',
  OTPVerification = 'verification',
  SelectApplicationType = 'select',
  GetStarted = 'start',
  ProviderDisclosures = 'disclosures',
  Application = 'app',
  DisclosureSubmit = 'submit',
  ProviderResponses = 'responses',
  ThankYou = 'thanks',
  // R:TODO E2E P2:R REMOVE THESE AFTER CONSOLDIATE
  MultiProviderDisclosures = 'multi-disclosures',
  PrimeProviderSingleFlow = 'single-disclosures',
}

export enum ErrorRoutes {
  Unauthorized = '401',
  NotFound = '404',
  ServerError = '500',
  CatchAll = '*',
}
