import React from 'react';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { ProviderOffersI } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { NotOffersI } from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-logic/bread-provider-response-logic';
import { AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';
import { checkIfBread, checkIfBreadId } from 'src/utils/bread-e2e-constants';

interface WaterfallContinuePipingCardProps {
  onClick: () => void;
  isBread?: boolean;
  isBreadError?: boolean;
  offers: ProviderOffersI[];
  notOffers: NotOffersI[];
}
export default function BreadMoveForwardCard({
  onClick,
  isBread = false,
  isBreadError = false,
  offers,
  notOffers,
}: WaterfallContinuePipingCardProps) {
  const getCardText = () => {
    const breadNotOffers = notOffers.find((no) => checkIfBreadId(no.lender.lender_id));
    let isPending;
    // TODO - i don't know what logic isBreadError is doing and couldn't force a pending so we need to test and clean this up
    const breadPendingMsg =
      'By selecting "Additional Options" I agree that I am proceeding with additional financing or payment option provider(s). This may result in additional inquires on my credit report. Selecting additional options does not cancel the Petland Credit Card and if fully approved, it will still be sent to me.';
    if (breadNotOffers) {
      isPending = breadNotOffers.sub_details.lender_response === AppSubLenderResponseE.pending;
    }
    if (isBread && isBreadError) {
      if (isPending) {
        return breadPendingMsg;
      } else {
        // errors
        return 'Proceeding with another provider: By selecting "Additional Options" you agree to be taken to an additional financing offer with another provider which could result in an additional credit inquiry. In the event you are approved for an account through this application process and the Comenity Capital Bank application process, two accounts will be open for use at participating Petland locations.';
      }
    }
    // TODO - need to add proper pending message here
    if (isBread) {
      const hasOffersFromBread = offers.find((o) => checkIfBreadId(o.lender.lender_id));
      if (hasOffersFromBread) {
        return 'By selecting "Additional Options" I agree that I am proceeding with additional financing or payment option provider(s). This may result in additional inquiries on my credit report. Selecting additional options does not cancel the Petland Credit Card and it will still be sent to me.';
      } else {
        if (isPending) {
          return breadPendingMsg;
        }
        // declined
        return 'By selecting "Additional Options" I agree that I am proceeding with additional financing or payment option provider(s). This may result in additional inquiries on my credit report.';
      }
    }
    return 'Would you like to move forward with additional providers?';
  };

  const buttonText = isBread ? 'Additional Options' : 'Continue';

  return (
    <ThemedCard sx={{ backgroundColor: '#F3F6FB', height: 220, marginTop: 2, marginLeft: 3 }}>
      <CardContent>
        <Typography variant="body1" color="textSecondary" sx={{ marginBottom: '40px' }}>
          {getCardText()}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="warning"
            sx={{
              minWidth: '300px',
              backgroundColor: '#ACBAE3',
              ':hover': {
                backgroundColor: '#ACBAE3',
              },
              color: '#FFF',
              marginBottom: "20px"
            }}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Box>
      </CardContent>
    </ThemedCard>
  );
}
