import React from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, Link, Typography } from '@mui/material';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';

import { BreadSubmitDisclosureLogic_functionsAndVals } from './bread-logic/bread-submit-disclosure-logic';
import theme from 'src/e2e-redesign/theme';

//TODO BREAD -- The styling in here is super basic and just a bunch of BS, actually make it match the styling provided on the deck

export default function BreadSubmitDisclosure() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  const v = BreadSubmitDisclosureLogic_functionsAndVals(routingPackage);
  const { handleSubmit } = v.fn;
  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.primary.main }}>
        Submit your application
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        I understand that by checking the <strong>"I Consent"</strong> box and clicking
        <strong> "Submit,"</strong> I agree to the Terms and Conditions, acknowledged receipt of the Privacy Notice,
        consent to electronically receive documents, and express my intent that this application/solicitation.
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        I expressly authorize FormPiper and its network of providers to obtain and use my consumer credit report and
        other submitted information, and I acknowledge there may be multiple credit inquiries that might impact my
        credit profile or credit score.
      </Typography>

      <Box
        sx={{
          backgroundColor: '#f9fafb',
          borderLeft: '4px solid #d3d3d3',
          p: 2,
          mb: 2,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
          IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
        </Typography>
        <Typography variant="body1">
          To help the government fight the funding of terrorism and money laundering activities, federal law requires
          all financial institutions to obtain, verify, and record information that identifies each person who opens an
          account.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          What this means for you: When you open an account, we will ask for your name, address, date of birth, and
          other information that will allow us to identify you. We may also ask to see your driver’s license or other
          identifying documents.
        </Typography>
      </Box>

      {/*TODO BREAD - Where do we save this?*/}
      <FormControlLabel control={<Checkbox color="primary" />} label="I consent" sx={{ mb: 3 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Button variant="contained" color="warning" onClick={() => handleSubmit()}>
          Submit
        </Button>

        <Link
          component="button"
          type="button"
          variant="body2"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'text.primary',
          }}
        >
          Cancel Application
        </Link>
      </Box>
    </Container>
  );
}
