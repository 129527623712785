import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import { E2eOverrideScreenDetailsI } from 'src/interfaces/store-settings.interfaces';

export interface ThankYouComponentProps {
  typd: E2eOverrideScreenDetailsI;
}
export default function BreadThankYouComponent(props: ThankYouComponentProps) {
  const { typd } = props;

  return (
    <>
      <Seo title="Thank You" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap={4}
        p={3}
        borderRadius={2}
        boxShadow={3}
        bgcolor="background.paper"
      >
        <CheckCircleOutlineIcon color="primary" style={{ fontSize: 80 }} />
        {/* <img src="/assets/e2e-redesign/images/formpiper-bird-blue.png" alt="FormPiper Logo" style={{ width: '80px', height: '80px' }} /> */}
        <Typography variant="h4" component="h1" gutterBottom>
          {typd?.header ? typd.header : 'Thank You!'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {typd?.body ? typd.body : ''}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {typd?.disclosure ? typd.disclosure : ''}
        </Typography>
        <Button variant="contained" color="primary" size="large" href="/dashboard">
          Go to Dashboard
        </Button>
      </Box>
    </>
  );
}
