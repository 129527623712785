import { useCallback, useEffect, useState } from 'react';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import { breadConfigLogic_updateAppConfigurationObject } from 'src/e2e-redesign/business-logic/bread-logic/bread-path-logic';
import { breadHandleE2eRoutingLogic } from 'src/e2e-redesign/business-logic/bread-logic/bread-routing-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { E2eAppSettingsFormPathsI, E2eAppSettingsFormsI } from 'src/interfaces/application-settings.interfaces';
import { E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { BREAD_LENDER_ID } from 'src/utils/bread-e2e-constants';

export const selectAppLogic_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };

  e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
};

export interface LenderDetails {
  image_path: string;
  name: string;
  product_details: {
    html: string;
  }[];
}

export interface SelectAppLogicI extends ComponentLogicI {
  fn: {
    toggleDrawer: (index: number) => () => void;
    handleSelectPathType: (path: E2eAppSettingsFormPathsI) => void;
    getLenderDetailsWithLogos: (path: E2eAppSettingsFormPathsI) => void;
  };
  v: {
    drawerStates: boolean[];
    e2eForm: E2eAppSettingsFormsI | undefined;
    e2ePaths: E2eAppSettingsFormPathsI[];
    lenderDetailsWithLogos: LenderDetails[];
  };
}
export const selectAppLogic_functionsAndVals = (routingPackage: E2eLogicObjectsWithAasoParams): SelectAppLogicI => {
  const { params, e2eStore, aasoContext } = routingPackage;
  const aaso = aasoContext.aaso;
  const { e2e_form_id } = params;
  const [lenderDetailsWithLogos, setLenderDetailsWithLogo] = useState<LenderDetails[]>([]);
  const [e2ePaths, setE2ePaths] = useState<E2eAppSettingsFormPathsI[]>([]);
  const e2eForm = e2eStore.application_settings?.e2e?.e2e_forms.find((form) => form.id === e2e_form_id);

  useEffect(() => {
    const newPaths: E2eAppSettingsFormPathsI[] = [];
    if (e2eForm && e2eForm?.paths?.length > 0 && aaso) {
      const pastPathIds = aaso?.past_path_ids;
      const paths = e2eForm.paths;
      if (pastPathIds && pastPathIds.length > 0) {
        for (const p of paths) {
          if (!pastPathIds?.includes(p.id)) {
            newPaths.push(p);
          }
        }
        setE2ePaths(newPaths);
      } else {
        setE2ePaths(paths);
      }
    }
  }, [e2eForm, aaso]);

  //TODO BREAD -- we can probably move this logic so we never load this component, which would make more sense
  // but this contains it just to bread which is better imo
  useEffect(() => {
    //TODO BREAD -- This can be an infinite loop, so need conditions that prevent it from running...
    if (e2ePaths.length === 1 && aaso?.current_path_id !== e2ePaths[0].id) {
      handleSelectPathType(e2ePaths[0]);
    } else if (e2ePaths.length > 1) {
      for (const path of e2ePaths) {
        if (path.lenders.includes(BREAD_LENDER_ID)) {
          handleSelectPathType(path);
        }
      }
    }
  }, [e2ePaths]);

  const updateAaso = useE2eStore((store: E2eGlobalStore) => store.updateAASO);
  const [drawerStates, setDrawerStates] = useState<boolean[]>(e2ePaths.map(() => false));
  const toggleDrawer = useCallback(
    (index: number) => () => {
      setDrawerStates((prevStates) => prevStates.map((state, i) => (i === index ? !state : state)));
    },
    [],
  );

  useEffect(() => {
    if (e2ePaths.length > 0) {
      setDrawerStates(e2ePaths.map(() => false));
    }
  }, [e2ePaths]);

  const handleSelectPathType = (path: E2eAppSettingsFormPathsI) => {
    // user selected their path - update aaso

    const updatedAaso = {
      path_index: e2ePaths.findIndex((p) => p.id === path.id),
      current_path_id: path.id,
      current_stage: ApplicationStage.SELECTED_PATH,
    };
    updateAaso({
      data: { ...updatedAaso },
      sync: true,
    });
    if (aaso) {
      routingPackage.aasoContext.aaso = {
        ...aaso,
        ...updatedAaso,
      };
    }
    breadConfigLogic_updateAppConfigurationObject(routingPackage);
    // route
    const args: E2eRoutingArgsI = {
      currentStage: ApplicationStage.SELECTED_PATH,
      currentE2eRoute: E2ERoutes.Dashboard,
    };
    breadHandleE2eRoutingLogic(routingPackage, args);
  };

  function getLenderDetailsWithLogos(path: E2eAppSettingsFormPathsI) {
    const lds: any[] = [];
    const lenders = e2eStore.lenders;
    const disclosures = e2eStore.disclosures;
    path.lenders.forEach((p) => {
      if (lenders && disclosures) {
        const lenderDetails = lenders.find((l) => l.lender_id == p);
        const disclosure = disclosures.find((d) => d.lender_id == p);
        if (lenderDetails && lenderDetails?.product_details && lenderDetails?.product_details?.length > 0) {
          lds.push({
            ...lenderDetails,
            image_path: disclosure?.logo ? disclosure?.logo : lenderDetails?.logo_url,
          });
        }
      }
    });
    setLenderDetailsWithLogo(lds);
  }

  return {
    fn: {
      toggleDrawer,
      handleSelectPathType,
      getLenderDetailsWithLogos,
    },
    v: {
      drawerStates,
      e2eForm,
      e2ePaths,
      lenderDetailsWithLogos,
    },
  };
};
