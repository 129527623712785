import React from 'react';
import { Box, Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { breadLogicUtils_getBaseRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { CreateAccountCommsValuesI } from 'src/e2e-redesign/views-bread/BreadCreateAccountNewCustomer/bread-logic/bread-create-account-logic';
import { DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';

interface Props {
  initialValues: CreateAccountCommsValuesI;
  onSubmit: (values: CreateAccountCommsValuesI) => void;
}

const CommunicationForm: React.FC<Props> = (props: Props) => {
  const routingPackage: E2eLogicObjectsWithParams = breadLogicUtils_getBaseRoutingLogicObjects();
  const disclosures = routingPackage.e2eStore.disclosures;
  const fp_additional_communication = disclosures.find(
    (d) => d.disclosure_type === DisclosureTypeE.fp_additional_communications,
  );
  const { initialValues, onSubmit } = props;
  initialValues.fp_additional_communications.diclosure_id =
    fp_additional_communication?._id ?? initialValues.fp_additional_communications.diclosure_id;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <Box sx={{ mb: 2 }}>
            <Field
              component={CheckboxWithLabel}
              name="fp_sms_consent[accepted]"
              type="checkbox"
              Label={{ label: 'I agree to the above terms.' }}
            />
          </Box>
          <Box sx={{ mb: 6 }}>
            <Field
              component={CheckboxWithLabel}
              name="fp_additional_communications[accepted]"
              type="checkbox"
              Label={{
                label: fp_additional_communication && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fp_additional_communication.document,
                    }}
                  />
                ),
              }}
            />
          </Box>
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Button
              variant="contained"
              color="warning"
              size="large"
              type="submit"
              disabled={isSubmitting || !values.fp_sms_consent.accepted}
            >
              Continue
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CommunicationForm;
