/* eslint-disable */
import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FieldRenderProps, FormRenderProps } from 'react-final-form';
import { useParams } from 'react-router-dom';
import AlphaNumeric from 'src/components/masks/alpha-numeric';
import CreditCardMask from 'src/components/masks/credit-card-mask';
import FullDateMask from 'src/components/masks/full-date-mask';
import CurrencyDecimalInput from 'src/components/masks/money-decimal-mask';
import CurrencyInput from 'src/components/masks/money-mask';
import MonthYearMask from 'src/components/masks/month-year-mask';
import NumberMask from 'src/components/masks/number-mask';
import PercentMask from 'src/components/masks/percent-mask';
import PhoneMask from 'src/components/masks/phone-mask';
import SsnMask from 'src/components/masks/ssn-mask';
import TimeInMonths from 'src/components/masks/time-in-months-mask';
import { FieldConditionActionTypes, RootFieldDetailsI } from 'src/interfaces/application.interfaces';
import { getSpecialMessage } from 'src/pages/application/components/form-disclosures';
import SensitiveInput from 'src/pages/application/components/sensitive-input';
import StateSelector from 'src/pages/application/components/state-selector';
import YearSelector from 'src/pages/application/components/year-selector';

import AccordionMessage from './accordion-message';

// import ReactTextField from './react-final-form-fields/special-text-field';

interface MaskSelectorProps {
  formProps: FormRenderProps<any>;
  containsBread: Boolean;
  objSchema: RootFieldDetailsI & { hide_element_disc?: boolean };
  fieldProps: FieldRenderProps<any>;
  keyValue: string;
  storeName?: string;
  // these are for the preview
  disabled?: boolean;
  removeRequiredAsterisk?: boolean;
  required_fields?: string[];
  // modified line
  registerFieldRef?: (fieldId: string, element: HTMLElement | null) => void;
  // modified line
  handleFieldBlur?: (fieldId: string) => void;
}

export const getMaskType = (type: any) => {
  switch (type) {
    case 'phone':
      return PhoneMask;
    case 'ssn':
      return SsnMask;
    case 'fullDate':
      return FullDateMask;
    case 'monthYear':
      return MonthYearMask;
    case 'currency':
      return CurrencyInput;
    case 'currency_decimal':
      return CurrencyDecimalInput;
    case 'number':
      return NumberMask;
    case 'alphaNumeric':
      return AlphaNumeric;
    case 'timeInMonths':
      return TimeInMonths;
    case 'creditCard':
      return CreditCardMask;
    case 'percent':
      return PercentMask;
    default:
      NumberMask;
    // default: StandardInput()
  }
};

const DynamicInput = (props: MaskSelectorProps) => {
  // modified line - Added registerFieldRef and handleFieldBlur
  const {
    objSchema,
    keyValue,
    storeName,
    formProps,
    fieldProps,
    containsBread,
    disabled,
    removeRequiredAsterisk,
    required_fields,
    registerFieldRef,
    handleFieldBlur,
  } = props;
  const { touched, errors, values } = formProps;
  const { label, component, type, disclosure, msg, mask, required, least_common_options } = objSchema;
  const { token } = useParams();
  const disabledFields = ['first_name', 'last_name', 'email', 'mobile_phone'];
  const {
    input: { name, onChange, onBlur, value, ...restInput },
    meta,
    ...rest
  } = fieldProps;

  const key = keyValue;
  // const isDisabledField = disabledFields.includes(objSchema.name);
  const isDisabledField =
    process.env.REACT_APP_ENVIRONMENT === 'production' && !containsBread
      ? disabledFields.includes(objSchema.name)
      : false;

  /**
   * ToDo:
   * - I got this working, but I still need to incorporate the following:
   *  - msg
   *  - disclosure
   */

  const handleFieldErrors = (meta: any) => {
    if (token) {
      return required && !value ? true : undefined;
    } else {
      return meta.error && meta.touched;
    }
  };

  const getName = (name: any) => {
    const requiredName = `${name} *`;
    if (objSchema?.condition?.type === FieldConditionActionTypes.require) {
      return requiredName;
    }
    if (required_fields && required_fields.length > 0) {
      if (required_fields.includes(key)) {
        return requiredName;
      }
    } else if (objSchema.required) {
      return requiredName;
    } else {
      if (required && !removeRequiredAsterisk) {
        return requiredName;
      }
    }
    return name;
  };

  const textField = (objSchema: any) => {
    // modified line
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (inputRef.current) {
        // Find the actual input element within the TextField
        const inputElement = inputRef.current.querySelector('input');
        if (inputElement) {
          registerFieldRef?.(key, inputElement);
        }
      }
    }, []);

    const [accordionOpen, setAccordionOpen] = useState(false);
    // end modified
    const { acc_msg } = objSchema; // Needed for the accordion message

    const handleFocus = () => {
      if (acc_msg && !accordionOpen) {
        setAccordionOpen(true);
      }
    };

    const handleOnBlurAccordion = () => {
      if (accordionOpen) {
        setAccordionOpen(false);
      }
    };

    // const isAutoPopulated = !!(objSchema.value && !value.trim());
    // const isAutoPopulated = disabledFields.includes(objSchema.name);
    return (
      <>
        <div ref={inputRef}>
          <TextField
            {...rest}
            id={key}
            // modified
            inputRef={(el) => {
              const inputElement = el?.querySelector('input') || el;
              registerFieldRef?.(key, inputElement);
            }}
            onBlur={(e) => {
              onBlur(e);
              handleFieldBlur?.(key);
              handleOnBlurAccordion();
            }}
            // end modified
            InputProps={{
              inputComponent: getMaskType(mask) as any,
              // we want to remove theoutline border:
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                  outline: 'none',
                  boxShadow: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  outline: 'none',
                  boxShadow: 'none',
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              },
            }}
            fullWidth
            // style={{ border: '3px solid red' }}
            label={getName(label)}
            name={key}
            // modified line - Commented out since we are using the onBlur from the fieldProps
            // onBlur={onBlur}
            onChange={onChange}
            value={value || objSchema.value || ''}
            helperText={meta.touched ? meta.error : undefined}
            error={handleFieldErrors(meta)}
            variant="outlined"
            autoComplete="off"
            type={'search'} // this is to turn off 1Password autocomplete
            disabled={isDisabledField || disabled}
            className={`fs-mask`}
            onFocus={handleFocus}
          />
        </div>
        {acc_msg && (
          <AccordionMessage
            title={acc_msg.title}
            content={acc_msg.body}
            expanded={accordionOpen}
            handleChange={setAccordionOpen}
          />
        )}
      </>
    );
  };

  const dateField = (objSchema: any) => {
    // modified line
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const inputElement = inputRef.current.querySelector('input');
        if (inputElement) {
          registerFieldRef?.(key, inputElement);
        }
      }
    }, []);
    // end modified

    // TODO - need to test this
    return (
      <>
        <div ref={inputRef}>
          <LocalizationProvider id={key} name={key} dateAdapter={AdapterDayjs}>
            <DatePicker
              {...rest}
              // variant="inline"
              // format={objSchema.format ? objSchema.format : 'MM/dd/yyyy'}
              // margin="normal"
              // KeyboardButtonProps={{
              //   'aria-label': 'change date',
              // }}
              // id={key}
              // fullWidth
              // modified line - Commented out because we have to add the onBlur from the fieldProps
              // renderInput={(params) => <TextField {...params} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    onBlur(e);
                    handleFieldBlur?.(key);
                  }}
                />
              )}
              // end modified
              label={getName(label)}
              // name={key}
              // onBlur={onBlur}
              onChange={onChange}
              value={value.trim()}
              // helperText={meta.touched ? meta.error : undefined}
              // error={handleFieldErrors(meta)}
              // variant="outlined"
              // autoComplete="off"
              // type={'search'} // this is to turn off 1Password autocomplete
              disabled={disabled}
            />
          </LocalizationProvider>
        </div>
      </>
    );
  };

  const emailField = (objSchema: any) => {
    // modified
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const inputElement = inputRef.current.querySelector('input');
        if (inputElement) {
          registerFieldRef?.(key, inputElement);
        }
      }
    }, []);
    // end modified
    return (
      <>
        <TextField
          {...rest}
          id={key}
          InputProps={{
            inputComponent: getMaskType(mask) as any,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
          fullWidth
          label={getName(label)}
          name={key}
          // modified line - Commented out since we are using the onBlur from the fieldProps
          // onBlur={onBlur}
          onBlur={(e) => {
            onBlur(e);
            handleFieldBlur?.(key);
          }}
          // end modified
          onChange={onChange}
          value={value.trim() || (objSchema.value ? objSchema.value.trim() : '')}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          variant="outlined"
          autoComplete="off"
          type={'search'} // this is to turn off 1Password autocomplete
          disabled={isDisabledField || disabled}
          className={`fs-mask`}
        />
      </>
    );
  };

  const selectField = (objSchema: any) => {
    // modified line
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const selectElement = inputRef.current.querySelector('select');
        if (selectElement) {
          registerFieldRef?.(key, selectElement);
        }
      }
    }, []);
    // end modified

    // const items = applicationSelectObjects[objSchema.enum];
    // if it's state, I need to account for that
    let items = least_common_options;
    if (Array.isArray(items) && items.length <= 0) {
      items = objSchema?.base_options;
    }
    return (
      <div>
        <TextField
          select
          SelectProps={{ native: true }}
          id={key}
          label={getName(label)}
          fullWidth
          placeholder={getName(label)}
          name={key}
          className={`fs-mask`}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
          variant="outlined"
          // modified line - Commented out since we are using the onBlur from the fieldProps
          // onBlur={onBlur}
          onBlur={(e) => {
            onBlur(e);
            handleFieldBlur?.(key);
          }}
          // end modified
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          disabled={disabled}
        >
          {items.map((i: any) => (
            <option key={`${key}_${i.value}`} value={i.value}>
              {i.label}
            </option>
          ))}
        </TextField>
        {/*<FormHelperText style={{color: 'red'}}>{touched[key] && errors[key]}</FormHelperText>*/}
      </div>
    );
  };

  const yearsSelect = (objSchema: any) => {
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const selectElement = inputRef.current.querySelector('select');
        if (selectElement) {
          registerFieldRef?.(key, selectElement);
        }
      }
    }, []);
    // end modified
    const params = objSchema.params;

    return (
      <div ref={inputRef}>
        <YearSelector
          id={key}
          label={getName(label)}
          placeholder={getName(label)}
          name={key}
          variant="outlined"
          aheadOrBehind={params?.aheadOrBehind}
          maxOffset={params?.maxOffset}
          minOffset={params?.minOffset}
          spread={params?.spread}
          sort={params?.sort}
          // modified line - Commented out since we are using the onBlur from the fieldProps
          // onBlur={onBlur}
          onBlur={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onBlur(e);
            handleFieldBlur?.(key);
          }}
          // end modified
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          disabled={disabled}
        />
      </div>
    );
  };

  const stateSelect = (objSchema: any) => {
    // modified line
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const selectElement = inputRef.current.querySelector('select');
        if (selectElement) {
          registerFieldRef?.(key, selectElement);
        }
      }
    }, []);
    // end modified
    return (
      <div ref={inputRef}>
        <StateSelector
          id={key}
          label={getName(label)}
          placeholder={getName(label)}
          name={key}
          variant="outlined"
          // modified line - Commented out since we are using the onBlur from the fieldProps
          // onBlur={onBlur}
          onBlur={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onBlur(e);
            handleFieldBlur?.(key);
          }}
          // end modified
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          disabled={disabled}
        />
      </div>
    );
  };

  const sensitiveType = (objSchema: any) => {
    // modified line
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        const inputElement = inputRef.current.querySelector('input');
        if (inputElement) {
          registerFieldRef?.(key, inputElement);
        }
      }
    }, []);
    // end modified
    return (
      <div ref={inputRef}>
        <SensitiveInput
          id={key}
          objSchema={objSchema}
          label={getName(label)}
          placeholder={getName(label)}
          name={key}
          variant="outlined"
          // modified line - Commented out since we are using the onBlur from the fieldProps
          // onBlur={onBlur}
          onBlur={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onBlur(e);
            handleFieldBlur?.(key);
          }}
          // end modified
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          disabled={disabled}
        />
      </div>
    );
  };

  const getInputType = (objSchema: any) => {
    switch (component) {
      case 'select':
        return selectField(objSchema);
      case 'state':
        return stateSelect(objSchema);
      case 'years':
        return yearsSelect(objSchema);
      case 'sensitive':
        return sensitiveType(objSchema);
      case 'email':
        return emailField(objSchema);
      case 'date':
        return dateField(objSchema);
      case 'text':
      default:
        return textField(objSchema);
    }
  };

  const showSpecialDisclosure = () => {
    const msg = getSpecialMessage(objSchema, storeName);

    if (msg && msg.length > 0) {
      return <FormHelperText style={{ fontSize: 12 }}>{msg}</FormHelperText>;
    } else {
      return <></>;
    }
  };

  const hideSpecialDisclosure = objSchema.hide_element_disc;

  return (
    <>
      {getInputType(objSchema)}
      {!hideSpecialDisclosure && showSpecialDisclosure()}
    </>
  );
};

export default DynamicInput;
