import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { breadConfigLogic_buildProviderResponseLogicObject } from 'src/e2e-redesign/business-logic/bread-logic/bread-configuration-object-logic';
import { breadHandleE2eUserCompletedInitialSelectionRouting } from 'src/e2e-redesign/business-logic/bread-logic/bread-routing-logic';
import { breadSubLogic_handleAcceptAndProceed } from 'src/e2e-redesign/business-logic/bread-logic/bread-submission-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import {
  e2eLogic_getShowStatusIfExists,
  GroupedOfferI,
} from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-logic/bread-provider-response-logic';
import NewDumbLenderResponseTable from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-new-dumb-lender-response-table';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';

interface LenderOfferTableProps {
  // offer: ProviderOffersI[];
  groupedOffer: GroupedOfferI;
  index: number;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

const NewLenderResponseE2eHandler: React.FC<LenderOfferTableProps> = (props: LenderOfferTableProps) => {
  const { groupedOffer, index, routingPackage } = props;

  const offer = groupedOffer.offers[0];
  const obj = breadConfigLogic_buildProviderResponseLogicObject(offer);
  const appConfig = routingPackage.e2eStore.app_configurations;
  const updateAaso = useE2eStore((state) => state.updateAASO);
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const offerDetails: LenderOffersI | undefined = offer?.offer_details;
  const submissionDetails: ApplicationSubmissionStateDto = offer.sub_details;
  const { params } = routingPackage;
  if (!obj) return null;
  const offerLender: LendersI = offer.lender;
  const lenderDisclosures = offer.lender_disclosures;
  const addSubmission = routingPackage.e2eStore.addSubmission;
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const isPending = submissionDetails.lender_response === AppSubLenderResponseE.pending;

  const handleOfferSelection = (fpUniqueId: string) => {
    setSelectedOffer((prev) => (prev === fpUniqueId ? '' : fpUniqueId));
  };

  useEffect(() => {
    if (offerDetails && offerDetails.redirect_url && offerDetails.redirect_url.length > 0) {
      if (submissionDetails.submission_state === AppSubStatusE.redirected) {
        setRedirectUrl(offerDetails.redirect_url);
      } else {
        setRedirectUrl(undefined);
      }
    } else {
      setRedirectUrl(undefined);
    }
  }, [offerDetails, submissionDetails]);

  useEffect(() => {
    // handle update status
    const settings = e2eLogic_getShowStatusIfExists(appConfig?.laa_settings, offerLender);
    if (!settings) return;
    const lenderResponses = settings?.on?.lender_responses;
    if (settings && lenderResponses) {
      if (settings?.enabled && lenderResponses) {
        if (settings.disable_app_client) {
          setShowUpdateStatus(false);
        } else {
          // logic for showing Update Status
          const currentLenderResponse = submissionDetails.lender_response;
          if (lenderResponses.includes(currentLenderResponse)) {
            setShowUpdateStatus(true);
          } else {
            setShowUpdateStatus(false);
          }
        }
      } else {
        setShowUpdateStatus(false);
      }
    }
  }, [appConfig?.laa_settings, submissionDetails.lender_response, offerLender]);

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);

  const handleAcceptAndProceed = async (fp_unique_id: string) => {
    const selectedOffer = offer.sub_details.lender_offers?.find((o) => {
      return o.fp_unique_id === fp_unique_id;
    });

    try {
      if (selectedOffer) {
        await breadSubLogic_handleAcceptAndProceed(selectedOffer, offerLender, params, updateAaso);
        const routingArgs: E2eRoutingArgsI = {
          currentStage: ApplicationStage.FORM_SUBMITTED,
          currentE2eRoute: E2ERoutes.PrimeLenderInformationReview,
        };
        breadHandleE2eUserCompletedInitialSelectionRouting(routingPackage, routingArgs);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleUpdateStatus = async () => {
    // TODO E2E P0 - handle proceed for synchrony logic - update status
    try {
      const settings = e2eLogic_getShowStatusIfExists(appConfig?.laa_settings, offerLender);
      if (settings) {
        const submissionStates = settings?.on?.lender_responses;
        if (submissionStates) {
          addSubmission(routingPackage.params, [AppSubStatusE.lender_responded], Number(offer.lender.lender_id));
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <NewDumbLenderResponseTable
        submissionDetails={submissionDetails}
        index={index}
        offerLender={offerLender}
        lenderDisclosures={lenderDisclosures}
        offerDetails={offerDetails}
        offer={offer}
        isPending={isPending}
        selectedOffer={selectedOffer}
        handleOfferSelection={handleOfferSelection}
        showEcoa={showEcoa}
        redirectUrl={redirectUrl}
        showUpdateStatus={showUpdateStatus}
        handleUpdateStatus={handleUpdateStatus}
        handleAcceptAndProceed={handleAcceptAndProceed}
        disableActions={false}
      />
    </>
  );
};

export default NewLenderResponseE2eHandler;
