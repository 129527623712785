import React from 'react';
import styled from '@emotion/styled';

const breadDisclosureUrl = 'https://d.comenity.net/legaldocs/petland/legal-documents/termsandconditions';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 50vh;
  border: 1px solid lightgray;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default function BreadIframeDisclosure({ isActive = false }: { isActive?: boolean }) {
  if (!isActive) {
    return null;
  }

  return <StyledIframe title="disc_bread" src={breadDisclosureUrl} />;
}
