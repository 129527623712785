import { breadHandleE2eFormSubmittedRoutingLogic } from 'src/e2e-redesign/business-logic/bread-logic/bread-routing-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { BreadCFARoutes } from 'src/enums/routes.enums';

export interface BreadSubmitDisclosureLogicI extends ComponentLogicI {
  fn: {
    handleSubmit: () => void;
  };
  v: {};
}
export const BreadSubmitDisclosureLogic_functionsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
): BreadSubmitDisclosureLogicI => {
  function handleSubmit() {
    const { e2eStore } = routingPackage;
    e2eStore.setFormValues({ ...e2eStore.form_values, submitted: true });
    const args: E2eRoutingArgsI = {
      currentStage: ApplicationStage.FORM_SUBMITTED,
      currentE2eRoute: BreadCFARoutes.ProviderDisclosures,
    };
    breadHandleE2eFormSubmittedRoutingLogic(routingPackage, args);
  }
  return {
    fn: { handleSubmit },
    v: {},
  };
};
