import React, { useEffect } from 'react';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  providerDiscLogic_functionsAndVals,
  providerDiscLogic_init,
} from 'src/e2e-redesign/views-bread/BreadProviderDisclosures/bread-logic/bread-provider-disclosures-logic';
import ProviderSingleDisclosure from 'src/e2e-redesign/views-bread/BreadProviderDisclosures/bread-provider-single-disclosure/bread-provider-single-disclosure';
import MultiProviderDisclosures from 'src/e2e-redesign/views-bread/BreadProviderDisclosures/BreadMultiProviderDisclosure/BreadMultiProviderDisclosures';
import { fsTrackEvent } from 'src/utils/fullstory';

export default function BreadProviderDisclosures() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  providerDiscLogic_init(routingPackage);
  const fnv = providerDiscLogic_functionsAndVals(routingPackage);
  const { selectedDisclosure, hasMultipleDisclosures } = fnv.v;
  console.log(selectedDisclosure);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedDisclosure]);

  useEffect(() => {
    fsTrackEvent('Disclosures Started', {
      selectedDisclosure: selectedDisclosure?.lender_id,
    });
  }, []);
  /**
   * - Pull the lender disclosure
   *      - display disclosures and logo
   *          - FP will be first (if we are doing it)
   *          - If the lender has multiple disclosures, we will show
   *            all of the disclosures on the page.
   *  - User accepts (user cannot move forward if they don't accept)
   *        - update the AASO object for the lender they have accepted
   *  - User accepts last disclosure:
   *        - submit the disclosure data to the backend/db
   *        - update aaso object
   *            - they will now be going to the application page
   *                - src/e2e-redesign/views-bread/EnterPrimeProviderInformation/EnterPrimeProviderInformation.tsx
   *
   * Notes:
   * - Each provider disclosure will have it's own page so we need to figure out:
   *    - how are we going to save which disclosures they have accepted so we can route them if they refresh
   * - Once the user has accepted the last disclosure, we will submit the disclosure data to the backend/db
   *
   * Questions:
   * - Do we need to include FormPiper disclosures?
   *
   * Resources:
   * - lender disclosures - src/pages/disclosures/disclosures.tsx
   * - Fp disclosures - src/pages/disclosures/fp-disclosures.tsx
   * - See figma Meineke Flow -> Provider Disclosures (multiple mocks)
   */

  if (hasMultipleDisclosures && selectedDisclosure) {
    return <MultiProviderDisclosures selectedDisclosures={selectedDisclosure} routingPackage={routingPackage} />;
  } else {
    if (selectedDisclosure) {
      return <ProviderSingleDisclosure selectedDisclosures={selectedDisclosure} routingPackage={routingPackage} />;
    }
  }
  return <></>;
}
