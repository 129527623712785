import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes, NewE2ERoutes } from 'src/enums/routes.enums';

/**
 * This is where you add what route is what stage... by adding it, it will auto update when the user goes to the page
 */
export const stageTransitionMap = {
  // R:TODO E2E P0 - Remove these OLD E2E ROUTES
  [E2ERoutes.Welcome]: ApplicationStage.STARTED,
  [E2ERoutes.PrivacyPolicy]: ApplicationStage.DISCLOSURE_PRIVACY,
  [E2ERoutes.TermsAndConditions]: ApplicationStage.DISCLOSURE_TERMS,
  [E2ERoutes.LenderDisclosure]: ApplicationStage.DISCLOSURE_LENDERS,
  [E2ERoutes.PrimeApplication]: ApplicationStage.FORM_STARTED,
  [E2ERoutes.PrimeLenderInformationReview]: ApplicationStage.FORM_SUBMITTED,
  [E2ERoutes.PrimeApproval]: ApplicationStage.LENDER_SELECTED,
  [E2ERoutes.Dashboard]: ApplicationStage.COMPLETED,
  // NEW E2E ROUTES
  [NewE2ERoutes.GetStarted]: ApplicationStage.FLOW_STARTED,
  [NewE2ERoutes.ProviderDisclosures]: ApplicationStage.DISCLOSURE_LENDERS,
  [NewE2ERoutes.Application]: ApplicationStage.FORM_STARTED,
  [NewE2ERoutes.ProviderResponses]: ApplicationStage.FORM_SUBMITTED,
  [NewE2ERoutes.SelectApplicationType]: ApplicationStage.FLOW_STARTED,
  // [NewE2ERoutes.LandingPage]:
  // [E2ERoutes.PrivacyPolicy]: ApplicationStage.DISCLOSURE_PRIVACY,
  // [E2ERoutes.TermsAndConditions]: ApplicationStage.DISCLOSURE_TERMS,
  // [E2ERoutes.LenderDisclosure]: ApplicationStage.DISCLOSURE_LENDERS,
  // [E2ERoutes.PrimeApplication]: ApplicationStage.FORM_STARTED,
  // [E2ERoutes.PrimeLenderInformationReview]: ApplicationStage.FORM_SUBMITTED,
  // [E2ERoutes.PrimeApproval]: ApplicationStage.LENDER_SELECTED,
  // [E2ERoutes.Dashboard]: ApplicationStage.COMPLETED,
};

export const routeTransitionMap = {
  Started: E2ERoutes.Welcome, // temp aaso has been created
  DisclosurePrivacy: E2ERoutes.PrivacyPolicy,
  DisclosureTerms: E2ERoutes.TermsAndConditions,
  DisclosureLenders: E2ERoutes.LenderDisclosure,
  FormStarted: E2ERoutes.PrimeApplication,
  FormSubmitted: E2ERoutes.PrimeLenderInformationReview,
  LenderSelected: E2ERoutes.Dashboard,
  Completed: E2ERoutes.Dashboard,
  // NEW E2E ROUTES
  FlowStarted: NewE2ERoutes.GetStarted, // aaso has been created
  SubmittedToLender: NewE2ERoutes.ProviderResponses, // user has submitted app to a lender
  InitialSelection: E2ERoutes.Dashboard, // user has selected an initial offer
  SelectedPath: E2ERoutes.SelectApplicationType, // user has selected a path
};

/**
 * This is saying where the user can go from what stage
 */
export const validTransitions: Record<ApplicationStage, ApplicationStage[]> = {
  [ApplicationStage.STARTED]: [
    ApplicationStage.DISCLOSURE_PRIVACY,
    ApplicationStage.DISCLOSURE_TERMS,
    ApplicationStage.FLOW_STARTED,
  ],
  [ApplicationStage.DISCLOSURE_PRIVACY]: [ApplicationStage.DISCLOSURE_TERMS],
  [ApplicationStage.DISCLOSURE_TERMS]: [ApplicationStage.DISCLOSURE_LENDERS],
  [ApplicationStage.FORM_STARTED]: [ApplicationStage.FORM_SUBMITTED],
  [ApplicationStage.LENDER_SELECTED]: [ApplicationStage.COMPLETED],
  [ApplicationStage.COMPLETED]: [],
  // R:TODO E2E P0 - I believe I am using this correctly, but go through it and make sure
  // New E2E Transitions
  [ApplicationStage.FLOW_STARTED]: [ApplicationStage.FLOW_STARTED, ApplicationStage.DISCLOSURE_LENDERS],
  [ApplicationStage.DISCLOSURE_LENDERS]: [ApplicationStage.FORM_STARTED],
  //TODO BREAD -- @Alex, I had to add this and they should never see the new page but if they somehow did it would send them back to the start because they deviated onto the bread path somehow
  //Test this on normal CFA
  [ApplicationStage.DISCLOSURE_SUBMIT]: [ApplicationStage.FLOW_STARTED],
  [ApplicationStage.FORM_SUBMITTED]: [ApplicationStage.LENDER_SELECTED, ApplicationStage.FORM_SUBMITTED],
  [ApplicationStage.INITIAL_SELECTION]: [ApplicationStage.LENDER_SELECTED],
  [ApplicationStage.SUBMITTED_TO_LENDER]: [ApplicationStage.LENDER_SELECTED],
  [ApplicationStage.SELECTED_PATH]: [ApplicationStage.FLOW_STARTED],
  [ApplicationStage.THANK_YOU_PAGE]: [ApplicationStage.THANK_YOU_PAGE],
};
