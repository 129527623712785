import { useEffect, useState } from 'react';
import { API_E2E_POST_DISCLOSURES } from 'src/api/e2e-request-objects';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import { apiRequest } from 'src/api/request-handler';
import {
  E2eChunkedDisclosuresI,
  E2EChunkedLenderDisclosuresI,
  e2eDiscLogic_chunkDisclosures,
  e2eDiscLogic_doesLenderHaveMultipleDisclosures,
  e2eDiscLogic_hasAcceptedAllDisclosures,
  e2eDiscLogic_isDisclosure,
  e2eDiscLogic_isE2EChunkedLenderDisclosuresI,
  e2eDiscLogic_selectDisclosureToRender,
} from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI, handleE2eRoutingLogic, handleCancelApplicationRouting } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import { NewE2ERoutes } from 'src/enums/routes.enums';
import { Disclosure, DisclosureAasoI, DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';
import { LendersI } from 'src/interfaces/lenders.interfaces';

export const providerDiscLogic_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };

  e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
};

// R:TODO E2E P2 - refactor provider see below
/**
 * There is a lot of room to improve this.
 * - Can we combine multi/single into one component?
 * - E2EChunkedLenderDisclosuresI | Disclosure | null os messy
 *    - E2EChunkedLenderDisclosures - is for lenders as we need the lender object to provide some information
 *    - Disclosures is for all other types of disclosures (other, fp, integrations)
 * - combine providerDiscLogic_MultiFuncsAndVals && providerDiscLogic_childFuncsAndVals
 * - Add all of the data to the disclosure views
 * - Then we need to finish the logic for the disclosures:
 *    - don't allow them to go forward until the have hit the checkboxes
 *    - handle going back to the previous disclosure
 *    -
 * NOTE READ!!!:
 * I'm not sold on handling the routing this way... I just didn't want to mess with the routes
 * and create a new dynamic route, but it might be easier than handling it this way... maybe we:
 *  - create the chunkedDisclosures object and route based on that?
 */
export interface ProviderDisclosuresLogicI extends ComponentLogicI {
  fn: {};
  v: {
    hasFinished: boolean;
    selectedDisclosure?: E2EChunkedLenderDisclosuresI | Disclosure | null;
    hasMultipleDisclosures: boolean;
  };
}
export const providerDiscLogic_functionsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
): ProviderDisclosuresLogicI => {
  const { e2eStore, aasoContext, params } = routingPackage;
  const { aaso_id, e2e_form_id, store_uuid } = params;
  const aaso = aasoContext.aaso;
  const disclosures = e2eStore.disclosures;
  const aasoAcceptedDisclosures = e2eStore.aaso?.disclosures;

  const [isMulti, setIsMulti] = useState<boolean>(false);
  const [selectedDisclosure, setSelectedDisclosure] = useState<E2EChunkedLenderDisclosuresI | Disclosure>();
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [hasFinishedLenders, setHasFinishedLenders] = useState<boolean>(false);

  useEffect(() => {
    const lenders = e2eStore.lenders;
    if (lenders && Array.isArray(lenders)) {
      const e2eForm = e2eStore.application_settings?.e2e?.e2e_forms.find(
        (form) => form.id === e2eStore.aaso.e2e_form_id,
      );
      const e2ePath = e2eForm?.paths.find((p) => p.id === aaso?.current_path_id);

      if (e2ePath) {
        const cd: E2eChunkedDisclosuresI = e2eDiscLogic_chunkDisclosures(disclosures, e2eStore.lenders, e2ePath);
        const f = e2eDiscLogic_hasAcceptedAllDisclosures(cd, aasoAcceptedDisclosures);
        const sd = e2eDiscLogic_selectDisclosureToRender(cd, aasoAcceptedDisclosures);
        if (sd) {
          if (e2eDiscLogic_isDisclosure(sd)) {
          } else if (e2eDiscLogic_isE2EChunkedLenderDisclosuresI(sd)) {
            const multi: boolean = e2eDiscLogic_doesLenderHaveMultipleDisclosures(sd);
            setIsMulti(multi);
          }
        }
        setSelectedDisclosure(sd);
        setHasFinishedLenders(f);
      }
    }
  }, [e2eStore.lenders, disclosures, aaso, e2eStore.application_settings]);

  useEffect(() => {
    // TODO E2E P3 - handle for multi disclosures per processor and for integrations
    if (hasFinishedLenders) {
      // check for other disclosures (processor/integrations)
      const processor = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.processor);
      if (processor) {
        // have they signed it already
        const foundAasoDisclosure = aaso?.disclosures.find((ad) => ad._id === processor._id);
        if (foundAasoDisclosure) {
          setHasFinished(true);
        } else {
          setSelectedDisclosure(processor);
          setIsMulti(false);
        }
      } else {
        setHasFinished(true);
      }
    }
  }, [hasFinishedLenders, aaso]);

  const handleE2eDisclosures = async () => {
    if (hasFinished && aaso) {
      // R:TODO E2E P0 - we're going to save disclosures when the user submits the form so we can capture form_id
      const res = await apiRequest(API_E2E_POST_DISCLOSURES, {
        body: { disclosures: aaso.disclosures, applicant_id: aaso.applicant_id },
        params: { store_uuid, e2e_form_id, aaso_id },
      });
      if (res.success) {
        const routingArgs: E2eRoutingArgsI = {
          currentStage: aaso.current_stage,
          currentE2eRoute: NewE2ERoutes.ProviderDisclosures, // this doesn't matter
        };
        handleE2eRoutingLogic(routingPackage, routingArgs);
      }
    }
  };

  useEffect(() => {
    //Todo E2E: find a better way to handle this, it can be retriggered if they go back
    if (hasFinished && aaso) {
      handleE2eDisclosures();
    }
  }, [hasFinished]);

  return {
    fn: {},
    v: {
      hasFinished,
      selectedDisclosure,
      hasMultipleDisclosures: isMulti,
    },
  };
};

export interface ProviderDisclosuresChildLogicI extends ComponentLogicI {
  fn: {
    handleSingleDisclosureAccept: (values: any) => void;
    handleCancelApplication: () => void;
    handleGoBackDisclosures: () => void;
  };
  v: {
    disclosures: Disclosure | undefined;
    showGoBackButton: boolean;
  };
}
export const providerDiscLogic_childFuncsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure | undefined,
): ProviderDisclosuresChildLogicI => {
  const { e2eStore } = routingPackage;
  const aaso = e2eStore.aaso;
  const updateAaso = e2eStore.updateAASO;
  const [lenderDetails, setLenderDetails] = useState<LendersI>();
  const [disclosures, setDisclosures] = useState<Disclosure>();
  const showGoBackButton = false;

  useEffect(() => {
    if (selectedDisclosures) {
      if (e2eDiscLogic_isE2EChunkedLenderDisclosuresI(selectedDisclosures)) {
        setLenderDetails(selectedDisclosures.details);
        if (Array.isArray(selectedDisclosures.disclosures)) {
          setDisclosures(selectedDisclosures.disclosures[0]);
        } else {
          setDisclosures(selectedDisclosures.disclosures);
        }
      } else if (e2eDiscLogic_isDisclosure(selectedDisclosures)) {
        if (Array.isArray(selectedDisclosures)) {
          setDisclosures(selectedDisclosures[0]);
        } else {
          setDisclosures(selectedDisclosures);
        }
      }
    }
  }, [selectedDisclosures]);

  useEffect(() => {
    // R:TODO E2E P0 - how are we going to handle going back - see below
    /**
     * There is a back button on the disclosures page, BUT we aren't using
     * react-dom to navigate them so we either have to create our own way or
     * for now, I think we just remove the back button for them
     */
    // if they are on the first disclosure, remove back button
  }, []);

  const handleSingleDisclosureAccept = async (values: any) => {
    // R:TODO E2E P0 - see below and just verify this is working (remember ip comes from api)
    const currentDiscObj: DisclosureAasoI = {
      name: disclosures?.name ? disclosures.name : '',
      is_accepted: values.agreeTerms, // set is accepted
      date_accepted: new Date(), // get current date
      lender_id: disclosures?.lender_id,
      ip_address: 'get ip address', // get ip address
      opt_in_marketing: disclosures?.opt_in_marketing, // add if it exists
      _id: disclosures?._id,
      internal_name: disclosures?.internal_name,
    };

    const newDisclosures = [...aaso.disclosures, currentDiscObj];
    updateAaso({ data: { disclosures: newDisclosures }, sync: true });
  };

  const handleGoBackDisclosures = () => {
    // R:TODO E2E P0 - how are we going to handle going back - see below
    /**
     * There is a back button on the disclosures page, BUT we aren't using
     * react-dom to navigate them so we either have to create our own way or
     * for now, I think we just remove the back button for them
     */
  };

  const handleCancelApplication = () => {
    handleCancelApplicationRouting(routingPackage);
  };

  return {
    fn: {
      handleSingleDisclosureAccept,
      handleGoBackDisclosures,
      handleCancelApplication,
    },
    v: {
      disclosures,
      showGoBackButton,
    },
  };
};

export interface ProviderDisclosuresMultiLogicI extends ComponentLogicI {
  fn: {
    handleMultiDiscAccept: (values: any) => void;
    handleGoBackDisclosures: () => void;
    handleCancelApplication: () => void;
    transformName: (name: string) => string;
  };
  v: {
    disclosures: Disclosure[];
    showGoBackButton: boolean;
  };
}

export const providerDiscLogic_MultiFuncsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure | Disclosure[] | undefined,
): ProviderDisclosuresMultiLogicI => {
  const { e2eStore } = routingPackage;
  const aaso = e2eStore.aaso;
  const updateAaso = e2eStore.updateAASO;
  // const [showGoBackButton, setShowGoBackButton] = useState(false);
  const showGoBackButton = false;
  const [disclosures, setDisclosures] = useState<Disclosure[]>([]);

  useEffect(() => {
    if (selectedDisclosures) {
      if (e2eDiscLogic_isE2EChunkedLenderDisclosuresI(selectedDisclosures)) {
        setDisclosures(selectedDisclosures.disclosures);
      } else if (e2eDiscLogic_isDisclosure(selectedDisclosures)) {
        if (Array.isArray(selectedDisclosures)) {
          setDisclosures(selectedDisclosures);
        } else {
          setDisclosures([selectedDisclosures]);
        }
      }
    }
  }, [selectedDisclosures]);

  useEffect(() => {
    // R:TODO E2E P0 - how are we going to handle going back - see below
    /**
     * There is a back button on the disclosures page, BUT we aren't using
     * react-dom to navigate them so we either have to create our own way or
     * for now, I think we just remove the back button for them
     */
  }, []);

  const transformName = (name: string) => name.replace(/\s+/g, '_');

  const handleMultiDiscAccept = async (values: any) => {
    // R:TODO E2E P0 - verify it's working... also consolidate these?
    const updatedDisclosures: DisclosureAasoI[] = [];
    disclosures.forEach((d) => {
      const currentDiscObj: DisclosureAasoI = {
        name: d?.name ? d.name : '',
        is_accepted: !!values[transformName(d.name)], // set is accepted
        date_accepted: new Date(), // get current date
        lender_id: d?.lender_id,
        ip_address: 'get ip address', // get ip address
        opt_in_marketing: !!(d.opt_in_marketing && values[transformName(d.name)]),
        _id: d?._id,
        internal_name: d?.internal_name,
      };
      updatedDisclosures.push(currentDiscObj);
    });
    const newDisclosures = [...aaso.disclosures, ...updatedDisclosures];
    updateAaso({ data: { disclosures: newDisclosures }, sync: true });
  };

  const handleGoBackDisclosures = () => {
    // R:TODO E2E P0 - how are we going to handle going back - see below
    /**
     * There is a back button on the disclosures page, BUT we aren't using
     * react-dom to navigate them so we either have to create our own way or
     * for now, I think we just remove the back button for them
     */
  };

  const handleCancelApplication = () => {
    handleCancelApplicationRouting(routingPackage);
  };

  return {
    fn: {
      handleMultiDiscAccept,
      handleGoBackDisclosures,
      handleCancelApplication,
      transformName,
    },
    v: {
      disclosures,
      showGoBackButton,
    },
  };
};
