import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { breadConfigLogic_buildProviderResponseLogicObject } from 'src/e2e-redesign/business-logic/bread-logic/bread-configuration-object-logic';
import { breadHandleE2eUserCompletedInitialSelectionRouting } from 'src/e2e-redesign/business-logic/bread-logic/bread-routing-logic';
import { breadSubLogic_handleAcceptAndProceed } from 'src/e2e-redesign/business-logic/bread-logic/bread-submission-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { LaaSettingsI } from 'src/e2e-redesign/interfaces/laa-settings.interface';
import {
  e2eLogic_getShowStatusIfExists,
  GroupedOfferI,
} from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-logic/bread-provider-response-logic';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { E2eLenderObjectDetailsI, LendersI } from 'src/interfaces/lenders.interfaces';
import { E2eLenderShowGetStatusI } from 'src/interfaces/store-settings.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  LenderOffersDetailedI,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';
import { parseHtml } from 'src/utils/general';

import LenderResponseRow from './bread-new-lender-response-row';

interface LenderOfferTableProps {
  // offer: ProviderOffersI[];
  groupedOffer: GroupedOfferI;
  index: number;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

const borderlessTableStyle = {
  '& .MuiTableCell-root': {
    border: 'none',
    // width: '100%',
  },
};

export const tableCellStyle = {
  whiteSpace: 'nowrap',
  width: '1%',
};

export const EcoaMessage =
  'In accordance with the provisions of the Equal Credit Opportunity Act (ECOA) and the Fair Credit Reporting Act (FCRA), the provider(s) your information was submitted to will notify you within thirty (30) days of a denial of credit decisions and the reasons why that decision was made.';

const NewLenderOfferTable: React.FC<LenderOfferTableProps> = (props: LenderOfferTableProps) => {
  const { groupedOffer, index, routingPackage } = props;
  const offer = groupedOffer.offers[0];
  const obj = breadConfigLogic_buildProviderResponseLogicObject(offer);
  const appConfig = routingPackage.e2eStore.app_configurations;
  const updateAaso = useE2eStore((state) => state.updateAASO);
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const offerDetails: LenderOffersI | undefined = offer?.offer_details;
  const submissionDetails: ApplicationSubmissionStateDto = offer.sub_details;
  const { params } = routingPackage;
  if (!obj) return null;
  const offerLender: LendersI = offer.lender;
  const lenderDisclosures = offer.lender_disclosures;
  const addSubmission = routingPackage.e2eStore.addSubmission;
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const isSingleOffer = submissionDetails.lender_offers?.length === 1;
  const isPending = submissionDetails.lender_response === AppSubLenderResponseE.pending;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOfferSelection = (fpUniqueId: string) => {
    setSelectedOffer((prev) => (prev === fpUniqueId ? '' : fpUniqueId));
  };

  useEffect(() => {
    if (offerDetails && offerDetails.redirect_url && offerDetails.redirect_url.length > 0) {
      if (submissionDetails.submission_state === AppSubStatusE.redirected) {
        setRedirectUrl(offerDetails.redirect_url);
      } else {
        setRedirectUrl(undefined);
      }
    } else {
      setRedirectUrl(undefined);
    }
  }, [offerDetails, submissionDetails]);

  useEffect(() => {
    // handle update status
    const settings = e2eLogic_getShowStatusIfExists(appConfig?.laa_settings, offerLender);
    if (!settings) return;
    const lenderResponses = settings?.on?.lender_responses;
    if (settings && lenderResponses) {
      if (settings?.enabled && lenderResponses) {
        // logic for showing Update Status
        const currentLenderResponse = submissionDetails.lender_response;
        if (lenderResponses.includes(currentLenderResponse)) {
          setShowUpdateStatus(true);
        } else {
          setShowUpdateStatus(false);
        }
      } else {
        setShowUpdateStatus(false);
      }
    }
  }, [appConfig?.laa_settings, submissionDetails.lender_response, offerLender]);

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);

  const handleAcceptAndProceed = async (fp_unique_id: string) => {
    const selectedOffer = offer.sub_details.lender_offers?.find((o) => {
      return o.fp_unique_id === fp_unique_id;
    });

    try {
      if (selectedOffer) {
        await breadSubLogic_handleAcceptAndProceed(selectedOffer, offerLender, params, updateAaso);
        const routingArgs: E2eRoutingArgsI = {
          currentStage: ApplicationStage.FORM_SUBMITTED,
          currentE2eRoute: E2ERoutes.PrimeLenderInformationReview,
        };
        breadHandleE2eUserCompletedInitialSelectionRouting(routingPackage, routingArgs);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const getCorrectDeclinedMessage = () => {
    if (submissionDetails.lender_declined_message && submissionDetails.lender_declined_message.length > 0) {
      return submissionDetails.lender_declined_message;
    } else {
      return EcoaMessage;
    }
  };

  const getLenderLogo = () => {
    if (offerLender.logo_url) {
      return {
        src: offerLender.logo_url,
        alt: offerLender.name,
      };
    } else {
      if (lenderDisclosures && lenderDisclosures?.length > 0) {
        const disc = lenderDisclosures.find((ld) => ld.logo.length > 0);
        if (disc) {
          return {
            src: disc.logo,
            alt: offerLender.name,
          };
        }
      }
    }
    return false;
  };

  const lenderLogoDetails = getLenderLogo();
  const isFpDetailed = !isEmpty(offerDetails?.fp_detailed_app);

  type LenderOfferKey = keyof LenderOffersI;
  const isFieldUsed = (fieldName: LenderOfferKey): boolean => {
    return (
      offer.sub_details.lender_offers?.some((offer) => {
        const value = offer[fieldName];
        return value !== undefined && value !== null;
      }) ?? false
    );
  };

  const displayFields = {
    account_number: isFieldUsed('account_number'),
    approved_amount: isFieldUsed('approved_amount'),
    offer_name: isFieldUsed('offer_name'),
    rate: isFieldUsed('rate'),
    apr: isFieldUsed('apr'),
    term: isFieldUsed('term'),
    monthly_payment: isFieldUsed('monthly_payment'),
    application_id: isFieldUsed('application_id'),
    deferred_interest_period: isFieldUsed('deferred_interest_period'),
    minimum_spend: isFieldUsed('minimum_spend'),
    promotion_description: isFieldUsed('promotion_description'),
    max_finance_amount: isFieldUsed('max_finance_amount'),
    interest_rate: isFieldUsed('interest_rate'),
    down_payment: isFieldUsed('down_payment'),
  };
  const showPendingMessage = () => {
    if (submissionDetails.lender_pending_message && submissionDetails.lender_pending_message.length > 0) {
      return (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(submissionDetails.lender_pending_message)}
        </Typography>
      );
    }
    return null;
  };

  const handleUpdateStatus = async () => {
    // TODO E2E P0 - handle proceed for synchrony logic - update status
    try {
      const settings = e2eLogic_getShowStatusIfExists(appConfig?.laa_settings, offerLender);
      if (settings) {
        const submissionStates = settings?.on?.lender_responses;
        if (submissionStates) {
          addSubmission(routingPackage.params, [AppSubStatusE.lender_responded], Number(offer.lender.lender_id));
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const showNotFpDetailedBody = () => {
    return (
      <TableContainer
        sx={{
          ...borderlessTableStyle,
          ...fixedHeaderStyle,
        }}
      >
        {!isMobile && (
          <TableHead>
            <TableRow>
              {!isPending && !isSingleOffer && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Select Offer
                </TableCell>
              )}

              {displayFields.account_number && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Account Number
                </TableCell>
              )}
              <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                Response
              </TableCell>
              {displayFields.approved_amount && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Approved Amount
                </TableCell>
              )}
              {/* REMOVING FOR NOW FOR HFD, BUT CREDOVA NEEDS THIS */}
              {/* {displayFields.offer_name && ( */}
              {/*   <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}> */}
              {/*     Offer Name */}
              {/*   </TableCell> */}
              {/* )} */}
              {displayFields.rate && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Rate
                </TableCell>
              )}
              {displayFields.apr && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  APR
                </TableCell>
              )}
              {displayFields.interest_rate && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Interest Rate
                </TableCell>
              )}
              {displayFields.term && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Term
                </TableCell>
              )}
              {displayFields.monthly_payment && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Monthly Payment
                </TableCell>
              )}
              {displayFields.max_finance_amount && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Max Financed Amount
                </TableCell>
              )}
              {displayFields.application_id && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Application ID
                </TableCell>
              )}
              {displayFields.deferred_interest_period && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Deferred Interest Period
                </TableCell>
              )}
              {displayFields.minimum_spend && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Minimum Spend
                </TableCell>
              )}
              {displayFields.promotion_description && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Promotion Description
                </TableCell>
              )}
              {displayFields.down_payment && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Down Payment
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <LenderResponseRow
            card_details={offer}
            selectedOffer={selectedOffer}
            onSelect={(fpUniqueId: string) => handleOfferSelection(fpUniqueId)}
            displayFields={displayFields}
            isPending={isPending}
            isSingleOffer={isSingleOffer}
          />
        </TableBody>
      </TableContainer>
    );
  };
  const showFpDetailedBody = (fpd: LenderOffersDetailedI[]) => {
    return (
      <TableContainer
        sx={{
          ...borderlessTableStyle,
          ...fixedHeaderStyle,
        }}
      >
        {!isMobile && (
          <TableHead>
            <TableRow>
              {!isPending && !isSingleOffer && (
                <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                  Select Offer
                </TableCell>
              )}
              {fpd.map((d: LenderOffersDetailedI) => {
                if (d.show) {
                  return (
                    <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                      {d.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <LenderResponseRow
            card_details={offer}
            selectedOffer={selectedOffer}
            onSelect={(fpUniqueId: string) => handleOfferSelection(fpUniqueId)}
            displayFields={displayFields}
            isPending={isPending}
            isSingleOffer={isSingleOffer}
          />
        </TableBody>
      </TableContainer>
    );
  };

  const handleShowBody = () => {
    // handles whether or not it's fp_detailed_app
    const fpDetailedApp = offerDetails?.fp_detailed_app;
    if (isFpDetailed && fpDetailedApp) {
      return showFpDetailedBody(fpDetailedApp);
    } else {
      return showNotFpDetailedBody();
    }
  };

  const fixedHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'background.paper',
    zIndex: 2,
  };

  return (
    <Grid item xs={12} key={index}>
      <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '52px',
            marginBottom: '16px',
            '& img': {
              maxHeight: '100%',
              maxWidth: '100%',
              objectFit: 'contain',
            },
          }}
        >
          {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
        </Box>
        <Box
          component="dl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            overflowX: 'auto',
          }}
        >
          {handleShowBody()}
          {submissionDetails && submissionDetails?.lender_approved_message && (
            <Typography variant="body1" style={{ margin: '20px' }} paragraph>
              {parseHtml(submissionDetails?.lender_approved_message)}
            </Typography>
          )}
          {/* {offerDetails && offerDetails.alternate_message && ( */}
          {/*     <Typography variant="body1" style={{ margin: '20px' }} paragraph> */}
          {/*       {parseHtml(offerDetails.alternate_message)} */}
          {/*     </Typography> */}
          {/* )} */}
          {showPendingMessage()}
          {showEcoa && (
            <Typography variant="body1" style={{ margin: '20px' }} paragraph>
              {getCorrectDeclinedMessage()}
            </Typography>
          )}
          {redirectUrl && (
            <>
              <Typography variant="body1" style={{ margin: '20px' }} paragraph>
                Please finish the application on the providers page.
              </Typography>
              <Typography variant="body1" style={{ margin: '20px' }} paragraph>
                <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                  Click here
                </a>{' '}
                if you have not been redirected.
              </Typography>
            </>
          )}
          {showUpdateStatus && (
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto' }}>
              <Button variant="contained" color="success" fullWidth onClick={handleUpdateStatus}>
                Update Status
              </Button>
            </Box>
          )}
          {!isPending && (
            <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="success"
                disabled={!isSingleOffer && selectedOffer == ''}
                onClick={() => handleAcceptAndProceed(selectedOffer)}
                sx={{ width: isMobile ? '100%' : '25vw', maxWidth: '100%' }}
              >
                Proceed
              </Button>
            </Box>
          )}
        </Box>
      </ThemedCard>
    </Grid>
  );
};

export default NewLenderOfferTable;
