import React, { useEffect, useState } from 'react';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { GroupedOfferI } from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-logic/bread-provider-response-logic';
import { EcoaMessage } from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-new-lender-response-table';
import { AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';
import { BREAD_LENDER_ID as BREAD_ID } from 'src/utils/bread-e2e-constants';
import { parseHtml } from 'src/utils/general';

interface ResponseCardProps {
  // details: NotOffersI;
  groupedOffer: GroupedOfferI;
  lenderLogoDetails: any;
  submissionDetails: any;
  showUpdateStatus: any;
  showEcoa: any;
  handleUpdateStatus: () => Promise<void>;
}

export default function BreadNewDumbNotOfferCard(props: ResponseCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { groupedOffer, lenderLogoDetails, submissionDetails, showUpdateStatus, showEcoa, handleUpdateStatus } = props;
  const [disableUpdateStatus, setDisableUpdateStatus] = useState<boolean>(false);
  const handleUpdateStatusClick = async () => {
    setDisableUpdateStatus(true);
    await handleUpdateStatus();
    setTimeout(() => {
      setDisableUpdateStatus(false);
    }, 1000 * 5);
  };

  const renderDefinitionItem = (term: string, definition: string | number | undefined) => {
    if (definition === undefined || definition === '') return null;
    return (
      <>
        <Typography component="dt" variant="body1" style={{ color: theme.palette.text.primary }}>
          {term}
        </Typography>
        <Typography component="dd" variant="body1" style={{ color: theme.palette.primary.main }}>
          {definition}
        </Typography>
      </>
    );
  };
  //This is for non bread lender responses
  const updateWording = (word: string) => {
    if (word === AppSubLenderResponseE.rejected) {
      return 'Declined';
    }
    if (word === AppSubLenderResponseE.nr || word === AppSubLenderResponseE.pending) {
      return 'Pending';
    }
    return word;
  };

  function getLenderMessage() {
    if (submissionDetails.lender_approved_message) {
      return submissionDetails.lender_approved_message;
    }
    if (submissionDetails.lender_pending_message) {
      return submissionDetails.lender_pending_message;
    }
    if (submissionDetails.lender_declined_message) {
      return submissionDetails.lender_declined_message;
    }
  }
  // This is the Bread Logic to conditionally render the Bread Message content
  const isBread = groupedOffer.lender?.lender_id === BREAD_ID;
  const getBreadMessageContent = (response: string): { title: string; message: string } | null => {
    switch (response) {
      case AppSubLenderResponseE.processing_error:
        return {
          title: 'Processing Error',
          message: `We're sorry. There has been an unexpected error and this action cannot be completed right now. Your application has not been submitted. Please try again later`,
        };
      case AppSubLenderResponseE.timed_out:
        return {
          title: 'Time Out',
          message: `We're sorry. There has been an unexpected error and this action cannot be completed right now. Your application has not been submitted. Please try again later`,
        };
      //We are doing this this way so it matches the styling on the deck. these messages actually on the backend. I don't have time to decouple this and fix the styling outright
      case AppSubLenderResponseE.rejected:
      case AppSubLenderResponseE.pending:
        return {
          title: 'Notify by mail',
          message: getLenderMessage(),
        };
      default:
        return {
          title: submissionDetails?.lender_response,
          message: getLenderMessage(),
        };
    }
  };
  const breadMessageContent = isBread ? getBreadMessageContent(submissionDetails?.lender_response) : null;

  const getCorrectDeclinedMessage = () => {
    let message = EcoaMessage;
    if (submissionDetails.lender_declined_message && submissionDetails.lender_declined_message.length > 0) {
      message = submissionDetails.lender_declined_message;
    } else if (submissionDetails.lender_pending_message && submissionDetails.lender_pending_message.length > 0) {
      message = submissionDetails.lender_pending_message;
    }
    return (
      <Typography variant="body1" style={{ margin: '20px' }} paragraph>
        {parseHtml(message)}
      </Typography>
    );
  };

  return (
    <Box>
      <ThemedCard>
        <CardContent>
          {/* Logo Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 52,
              mb: 2,
            }}
          >
            {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
          </Box>

          {/* Non-Bread Content */}
          {!isBread && (
            <>
              <dl
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                }}
              >
                {renderDefinitionItem('Response', updateWording(submissionDetails.lender_response))}
              </dl>

              {submissionDetails?.lender_approved_message && (
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {parseHtml(submissionDetails.lender_approved_message)}
                </Typography>
              )}

              {submissionDetails?.lender_pending_message && (
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {parseHtml(submissionDetails.lender_pending_message)}
                </Typography>
              )}
            </>
          )}

          {/* Bread Content */}
          {isBread && breadMessageContent && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" gutterBottom>
                Response
              </Typography>
              <Typography variant="h6" color="primary" paragraph gutterBottom>
                {breadMessageContent.title}
              </Typography>
              <Typography variant="body1" color="primary" paragraph>
                {breadMessageContent.message}
              </Typography>
            </Box>
          )}

          {/* Update Status Button */}
          {showUpdateStatus && (
            <Box
              sx={{
                mt: 'auto',
                mb: 1,
              }}
            >
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handleUpdateStatusClick}
                disabled={disableUpdateStatus}
              >
                Update Status
              </Button>
            </Box>
          )}

          {/* ECOA Message */}
          {showEcoa && !isBread && getCorrectDeclinedMessage()}
        </CardContent>
      </ThemedCard>
    </Box>
  );
}
