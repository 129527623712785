import { ApplicationSubmissionStateDto } from 'src/interfaces/submissions.interfaces';

export const BREAD_LENDER_ID = process.env.REACT_APP_ENVIRONMENT === 'local' ? 2 : 2;
export const EASYPAY_ID = 3;
export const checkIfBread = (lenders?: number[]) => {
  if (lenders) {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      return lenders.includes(EASYPAY_ID);
    } else {
      return lenders.includes(BREAD_LENDER_ID);
    }
  }
  return false;
};
export const checkIfBreadId = (lenderId?: number) => {
  if (lenderId) {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      return lenderId == EASYPAY_ID;
    } else {
      return lenderId == BREAD_LENDER_ID;
    }
  }
  return false;
};
export const isBreadSubmission = (sub?: ApplicationSubmissionStateDto) => {
  if (sub) {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      return sub.lender_id == EASYPAY_ID;
    } else {
      return sub.lender_id == BREAD_LENDER_ID;
    }
  }
  return false;
};

export const getBreadSubmissionObj = (
  submissions?: ApplicationSubmissionStateDto[],
): ApplicationSubmissionStateDto | undefined => {
  console.log(submissions);
  if (submissions) {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      return submissions.find((s) => s.lender_id == EASYPAY_ID);
    } else {
      return submissions.find((s) => s.lender_id == BREAD_LENDER_ID);
    }
  }
};
