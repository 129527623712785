import React from 'react';
import { Grid } from '@mui/material';
import { Seo } from 'src/components/seo';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import BreadProviderDetailsDrawer from 'src/e2e-redesign/components/ProviderDetailsDrawer/BreadProviderDetailsDrawer';
import {
  selectAppLogic_functionsAndVals,
  selectAppLogic_init,
} from 'src/e2e-redesign/views-bread/BreadSelectedApplicationType/bread-logic/bread-select-app-logic';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import ApplicationCard from './BreadApplicationCard';

export default function BreadSelectApplicationType() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  selectAppLogic_init(routingPackage);
  const fnv = selectAppLogic_functionsAndVals(routingPackage);
  const { toggleDrawer, handleSelectPathType } = fnv.fn;
  const { drawerStates, e2ePaths } = fnv.v;

  return (
    <>
      <Seo title="Pathway" />
      <ThemedContainer>
        <PageIntro
          title="Please select your preferred pathway to proceed."
          paragraph="Review program details below and choose the path that suits you best. Keep in mind: your application may be sent to multiple payment options. Our goal is to connect you with the right payment option."
        />
        <Grid container spacing={3}>
          {e2ePaths.map((path: E2eAppSettingsFormPathsI, index) => (
            <>
              <Grid item xs={12} md={6}>
                <ApplicationCard
                  path={path}
                  title={path.name}
                  notice={path.description}
                  buttonText={path.button_text}
                  onDetailsClick={toggleDrawer(index)}
                  handleSelectPathType={() => handleSelectPathType(path)}
                />
              </Grid>
              <BreadProviderDetailsDrawer
                isDrawerOpen={drawerStates[index]}
                path={path}
                toggleDrawer={toggleDrawer(index)}
                showPathName={true}
              />
            </>
          ))}
        </Grid>
      </ThemedContainer>
    </>
  );
}
