import { breadConfigLogic_buildAppConfigurationObject } from 'src/e2e-redesign/business-logic/bread-logic/bread-configuration-object-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { AASO } from 'src/interfaces/aaso.interfaces';

// R:TODO E2E P0 - REFACTOR - this is very messy and opens up a ton of issue, see below
/**
 * - we're using aaso AND app_configurations to control where the path takes a user, when really we should just use one thing
 * - So now whenever the path changes, we need to update the aaso AND update app_configurations
 */
export const breadConfigLogic_updateAppConfigurationObject = (
  routingPackage: E2eLogicObjectsWithAasoParams,
  newAaso?: AASO,
) => {
  const setAppConfigurations = routingPackage.e2eStore.setAppConfigurations;
  const aaso = newAaso ? newAaso : routingPackage.aasoContext.aaso;
  const e2e_form_id = aaso?.e2e_form_id;
  const {
    application_settings,
    lender_ids,
    lenders,
    outside_form_details,
    store_settings,
    store_details,
    app_configurations,
  } = routingPackage.e2eStore;
  if (
    application_settings &&
    lender_ids &&
    lenders &&
    outside_form_details &&
    store_details &&
    store_settings &&
    e2e_form_id
  ) {
    const data = {
      application_settings,
      lender_ids,
      lenders,
      outside_form_details,
      store_settings,
      store_details,
      laa_settings: app_configurations?.laa_settings,
      franchise_settings: app_configurations?.franchise_settings,
    };
    const appSettings = breadConfigLogic_buildAppConfigurationObject(data, aaso, e2e_form_id);
    setAppConfigurations(appSettings);
  }
};
