import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { breadStageTransitionMap, breadValidTransitions } from 'src/interfaces/bread-transition-maps';

const transitionLog = (message: string) => {
  const stackLines = new Error().stack?.split('\n').slice(2);
  const callerLine = stackLines ? stackLines[0].trim() : '';
  console.warn(`Warning: ${message} (${callerLine})`);
};

export interface AASOContextType {
  aaso: AASO | undefined;
}

export const BreadAASOContext = createContext<AASOContextType | null>(null);

export const BreadAASOProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const e2eAaso = useE2eStore((store) => store.aaso);
  const updateE2eAaso = useE2eStore((store) => store.updateAASO);

  const location = useLocation();

  const e2eContextValue = useMemo(() => ({ aaso: e2eAaso }), [e2eAaso]);

  useEffect(() => {
    // this should update the aaso object whenever a route changes
    const path = location.pathname?.split('/').pop() as keyof typeof breadStageTransitionMap;
    const newStage = breadStageTransitionMap[path] as ApplicationStage;
    if (newStage && (e2eAaso?.current_stage !== newStage || e2eAaso?.current_stage === ApplicationStage.STARTED)) {
      const currentStage = e2eAaso?.current_stage;
      if (currentStage) {
        const validNextStages = breadValidTransitions[currentStage as ApplicationStage];
        console.log(currentStage, newStage, validNextStages);
        if (validNextStages && validNextStages.includes(newStage)) {
          updateE2eAaso({ data: { current_stage: newStage }, sync: true });
        } else {
          transitionLog(`Invalid transition from ${currentStage} to ${newStage}`);
        }
      } else {
        console.warn('Current stage is undefined');
      }
    }
  }, [location.pathname, e2eAaso?.current_stage]);

  return <BreadAASOContext.Provider value={e2eContextValue}>{children}</BreadAASOContext.Provider>;
};

export const useBreadAASO = (): AASOContextType => {
  const context = useContext(BreadAASOContext);
  if (!context) {
    throw new Error('useBreadAASO must be used within an AASOProvider');
  }
  return context;
};
