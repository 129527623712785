import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import LogoGrid from 'src/e2e-redesign/components/LogoGrid';
import BreadProviderDetailsDrawer from 'src/e2e-redesign/components/ProviderDetailsDrawer/BreadProviderDetailsDrawer';
import BreadIframeDisclosure from 'src/e2e-redesign/views/get-started/bread-iframe-disclosure';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';
import { BREAD_LENDER_ID } from 'src/utils/bread-e2e-constants';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import theme from '../../theme';
import { getStartedLogic_functionsAndVals, getStartedLogic_init } from './bread-logic/bread-get-started-logic';
import BreadLogoContainer from './BreadLogoContainer';

export default function BreadLetsGetStarted() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  getStartedLogic_init(routingPackage);
  const fnv = getStartedLogic_functionsAndVals(routingPackage);
  const { handleRouting, handleGoBack, toggleDrawer } = fnv.fn;
  const { multi_paths_exist, disclosureLogos, drawerState, e2ePath } = fnv.v;

  const isBread = e2ePath?.lenders.find((l) => l == BREAD_LENDER_ID);
  console.log(isBread, BREAD_LENDER_ID);

  /**
   * - When they hit "go to provider disclosures"
   *      - update AASO object
   *          - capture that they accepted... even though they aren't accepting
   *          - update the page they should be on
   *      - call routeUser function
   *          - we need to route them to the provider disclosures page
   *              - src/e2e-redesign/views-bread/ProviderDisclosures/ProviderDisclosures.tsx
   *
   * - Resources:
   *    - this page is probably the most similar to the "Welcome Aboard" (src/pages/welcome.tsx)
   *      but it's not the same, because they aren't really agreeing there's just additional legal stuff
   *    - See figma Meineke Flow -> Let's Get Started (multiple mocks)
   */

  return (
    <>
      <Seo title="Get Started!" />
      <ThemedContainer>
        <PageIntro title="Get Started!" paragraph="Payment or Finance Options" />
        {isBread ? (
          <BreadLogoContainer
            logo={{ src: '/static/logos/lenders/petland-cc.png', alt: 'Petland Credit Card' }}
            message="Primary Finance Option - Petland Credit Card"
          />
        ) : (
          <LogoGrid logos={disclosureLogos} />
        )}
        <Typography
          component="p"
          variant="body1"
          sx={{ paddingBottom: '20px', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
          onClick={toggleDrawer}
        >
          Click Here for Payment or Finance Option Details
        </Typography>
        {isBread && (
          <Typography>
            Credit card offers are subject to credit approval. The credit card account is issued by Comenity Capital
            Bank. Review Comenity Capital Bank’s{' '}
            <Link target="_blank" rel="noopener" href="https://d.comenity.net/ac/petland/public/privacy/financial">
              Financial Privacy Policy
            </Link>{' '}
            and{' '}
            <Link target="_blank" rel="noopener" href="https://d.comenity.net/ac/petland/public/privacy/online">
              Online Privacy Policy
            </Link>{' '}
            for more information. California Residents: Submit a{' '}
            <Link target="_blank" rel="noopener" href="https://calprivacy.comenity.com/s/">
              Data Request Form
            </Link>{' '}
            to make a Do Not Sell or Share My Personal Information request.
          </Typography>
        )}
        {isBread && <BreadIframeDisclosure isActive />}
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px', marginTop: '40px' }}>
          Your security is our priority. The FormPiper Platform is designed to securely connect you with various payment
          options in order to facilitate the processing of your information directly within their systems.
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px' }}>
          FormPiper is neither a lender nor a financial institution – our role is to simplify the process of applying
          for financing or lease-to-own arrangements. We have no discretion or authority to make any determination
          whether an application is approved or denied and have no influence over any application’s viability at any
          time; all financing decisions are made strictly in accordance with the terms and conditions of each individual
          payment option, over which FormPiper has no authority.
        </Typography>
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button variant="contained" color="warning" size="large" onClick={handleRouting}>
            Get Started
          </Button>

          {/** multi_paths_exist && (
            <Button
              type="button"
              onClick={handleGoBack}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'underline',
              }}
            >
              Back
            </Button>
          )**/}
        </Box>
        {e2ePath && (
          <BreadProviderDetailsDrawer
            isDrawerOpen={drawerState}
            path={e2ePath}
            toggleDrawer={toggleDrawer}
            showPathName={false}
          />
        )}
      </ThemedContainer>
    </>
  );
}
