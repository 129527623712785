import React from 'react';
import { Seo } from 'src/components/seo';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import ApplicationCard from 'src/pages/application/application-card';
import BreadApplicationCard from 'src/pages/application/bread-application-card';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import { e2eApp_functionsAndVals, e2eApp_init } from './bread-logic/bread-e2e-app-logic';

export default function BreadNewE2eApplication() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  e2eApp_init(routingPackage);
  const fnv = e2eApp_functionsAndVals(routingPackage);
  const { canViewPage, subTitle } = fnv.v;

  if (!canViewPage) return <></>;
  return (
    <>
      <Seo title="Application" />
      <ThemedContainer>
        {/**<PageIntro title="Let's get some information" paragraph={subTitle} />**/}
        <PageIntro title="Let's get some information" />
        <BreadApplicationCard title={''} subtitle={''} routingPackage={routingPackage} />
      </ThemedContainer>
    </>
  );
}
