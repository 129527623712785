import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { parseHtml } from 'src/utils/general';

interface AccordionMessageProps {
  title: string;
  content: string;
  expanded?: boolean;
  handleChange?: (isExpanded: boolean) => void;
}

const AccordionMessage: React.FC<AccordionMessageProps> = ({
  title,
  content,
  expanded = false,
  handleChange = () => {},
}) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        handleChange(!expanded);
      }}
      elevation={0}
      sx={{
        border: 'none',
        boxShadow: 'none',
        '&:before': {
          // This removes the divider line
          display: 'none',
        },
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls={`${title}-helper-content`}
        id={`${title}-helper-header`}
      >
        {title && title?.length > 0 && (
          <Typography variant="body2" color="text.primary">
            {title}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, marginTop: '-10px' }}>
        <Typography variant="body2" component="div" sx={{lineHeight: 1.1}}>
          {parseHtml(content)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMessage;
