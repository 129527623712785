import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LogoProps {
  src: string;
  alt: string;
}

interface BreadLogoContainerProps {
  logo: LogoProps;
  message: string;
}

export default function BreadLogoContainer({ logo, message }: BreadLogoContainerProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: '0 0 auto',
          maxWidth: { xs: '50%', md: '25%', lg: '20%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '15px',
        }}
      >
        <Box
          component="img"
          src={logo.src}
          alt={logo.alt}
          sx={{
            maxHeight: '50px',
            maxWidth: '160px',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Typography
        paragraph
        color={theme.palette.primary.main}
        variant="h5"
        sx={{
          flex: '1',
          marginLeft: 2,
          fontWeight: 'bold',
          marginBottom: '0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}