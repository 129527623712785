import React, { useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import theme from 'src/e2e-redesign/theme';
import { GroupedOfferI } from 'src/e2e-redesign/views-bread/BreadLenderResponses/bread-logic/bread-provider-response-logic';

interface LenderResponseTabsI {
  combinedResponses: GroupedOfferI[];
}
const LenderResponseTabs = (props: LenderResponseTabsI) => {
  const { combinedResponses } = props;
  const [value, setValue] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const tabsRef = useRef<HTMLDivElement>(null);
  const tabsPositionRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current && tabsPositionRef?.current) {
        const originalPosition = tabsPositionRef.current.getBoundingClientRect().top;
        setIsSticky(window.scrollY > originalPosition);
        const headerOffset = tabsRef.current.offsetHeight;
        const lenderElements = combinedResponses.map((_, index) => document.getElementById(`lender-${index}`));
        const activeIndex = lenderElements.findIndex((element) => {
          if (!element) return false;
          const rect = element.getBoundingClientRect();
          return rect.top <= headerOffset + 50 && rect.bottom >= headerOffset;
        });
        if (activeIndex !== -1) {
          setValue(activeIndex);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [combinedResponses]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const element = document.getElementById(`lender-${newValue}`);
    if (element && tabsRef.current) {
      const headerOffset = tabsRef.current.offsetHeight;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <Box ref={tabsPositionRef} />
      <Box
        ref={tabsRef}
        sx={{
          position: isSticky ? 'fixed' : 'static',
          top: isSticky ? 0 : 'auto',
          left: 0,
          right: 0,
          backgroundColor: 'background.paper',
          zIndex: 1000,
          boxShadow: isSticky ? '0px 2px 4px -1px rgba(0,0,0,0.2)' : 'none',
          transition: 'box-shadow 0.3s ease',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
              flexWrap: 'wrap',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {combinedResponses.map((offer, index) => (
            <Tab key={index} label={offer.lender.name} wrapped={isMobile} />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default LenderResponseTabs;
