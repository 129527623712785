import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { breadConfigLogic_testCheckStats } from 'src/e2e-redesign/business-logic/bread-logic/bread-configuration-object-logic';
import { E2eRoutingLogicI, E2eUrlAasoParamsI, E2eUrlParamsI } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import useE2eStore from 'src/e2e-store';
import { useAuth } from 'src/hooks/use-auth';
import { useBreadAASO } from 'src/hooks/use-bread-aaso';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';

export interface E2eLogicObjectsWithAasoParams extends E2eRoutingLogicI {
  params: E2eUrlAasoParamsI;
}

export interface E2eLogicObjectsWithParams extends E2eRoutingLogicI {
  params: E2eUrlParamsI;
}
export const breadLogicUtils_getRoutingLogicObjects = (): E2eLogicObjectsWithAasoParams => {
  const e2eStore = useE2eStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const aasoContext = useBreadAASO();
  const { store_uuid, aaso_id, e2e_form_id, store_name } = useParams<any>();
  if (!store_uuid || !aaso_id || !e2e_form_id || !store_name) {
    throw new Error('Missing key params');
  }
  const routingPackage = {
    e2eStore,
    navigate,
    location,
    auth,
    aasoContext,
    params: {
      store_uuid,
      aaso_id,
      e2e_form_id,
      store_name,
    },
  };
  breadConfigLogic_testCheckStats(routingPackage);
  return routingPackage;
};

const getUrlPathSegments = (url: string) => {
  const urlObj = new URL(url);

  // Get the 'returnTo' query parameter value
  const returnToValue = urlObj.searchParams.get('returnTo');
  if (returnToValue) {
    // Decode the URL component
    const decodedReturnTo = decodeURIComponent(returnToValue);
    const newUrl = new URL(decodedReturnTo);
    // Split the pathname into segments
    return newUrl.pathname.split('/').filter((segment) => segment !== '');
  }
  return [];
};

const getReturnToStoreParams = (url: string, index: number) => {
  // Parse the given URL
  const pathSegments = getUrlPathSegments(url);
  if (pathSegments.length > 0) {
    return pathSegments[index];
  } else {
    return false;
  }
};

export const breadLogicUtils_getBaseRoutingLogicObjects = (): E2eLogicObjectsWithParams => {
  const e2eStore = useE2eStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const aasoContext = useBreadAASO();
  let { store_uuid, e2e_form_id, store_name } = useParams<any>();

  if (!store_uuid || !e2e_form_id || !store_name) {
    /// check if it's the login page
    const win = window.location.href;
    let storeIndex = 0,
      storeUuidIndex = 1,
      e2eFormIndex = 2;
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      storeIndex = 0;
      storeUuidIndex = 1;
      e2eFormIndex = 2;
    }
    const storeName = getReturnToStoreParams(win, storeIndex);
    const storeUuid = getReturnToStoreParams(win, storeUuidIndex);
    const e2eFormId = getReturnToStoreParams(win, e2eFormIndex);
    if (!storeUuid && !storeName && !e2eFormId) {
      store_uuid = '';
      store_name = '';
      e2e_form_id = '';
    } else if (storeUuid && storeName && e2eFormId) {
      store_uuid = storeUuid;
      store_name = storeName;
      e2e_form_id = e2eFormId;
    } else {
      store_uuid = '';
      store_name = '';
      e2e_form_id = '';
    }
  }
  return {
    e2eStore,
    navigate,
    location,
    auth,
    aasoContext,
    params: {
      store_uuid,
      e2e_form_id,
      store_name,
    },
  };
};

export const breadLogicUtils_addUniquePastPath = (
  pastPathIds: string[],
  pastPathIndex: number[],
  formPaths: E2eAppSettingsFormPathsI[],
  newPathId: string,
): {
  past_path_index: number[];
  past_path_ids: string[];
  new_path_index?: number;
} => {
  const pastPathIdsSet = new Set(pastPathIds);
  const pastPathIndexesSet = new Set(pastPathIndex);
  const path_index = formPaths.findIndex((path) => path.id === newPathId);
  function addUniqueToArray(set: any, item: any) {
    set.add(item);
  }
  addUniqueToArray(pastPathIdsSet, newPathId);
  addUniqueToArray(pastPathIndexesSet, path_index);
  return {
    past_path_index: [...pastPathIndexesSet],
    past_path_ids: [...pastPathIdsSet],
    new_path_index: path_index,
  };
};
