import React from 'react';
import { Button } from '@mui/material';
import { Seo } from 'src/components/seo';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import ThemedHtml from '../../components/ThemedHtml/ThemedHtml';

const mockData = `
  <ol>
    <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident facere eum obcaecati pariatur magnam eius fugit nostrum sint enim, amet rem aspernatur distinctio tempora repudiandae, maiores quod.</li>
    <li>Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis sollicitudin mauris.</li>
    <li>Integer in mauris eu nibh euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est euismod turpis, id tincidunt sapien risus a quam.</li>
    <li>Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat.</li>
    <li>Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat.</li>
    <li>Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat.</li>
  </ol>
`;

export default function BreadCurrentCustomerAcceptChanges() {
  console.log('New CurrentCustomerAcceptChanges Page');
  // R:TODO E2E P0 - need to handle flow when changes to FP discs happen so the user accepts them all
  /**
   * - pull the latest version of changes
   *      - need to check all of formpipers disclosures and show them here
   *
   * Resources:
   * - Meineke Figma Mocks -> current customer accept changes
   */
  return (
    <>
      <Seo title="Review Changes" />
      <ThemedContainer>
        <PageIntro
          title="Changes to our XXXXXXXXX"
          paragraph="Please review the following changes and accept them to proceed."
        />
        <ThemedHtml htmlContent={mockData} />
        <Button variant="contained" color="warning" size="large" sx={{ mt: 2 }}>
          Continue
        </Button>
      </ThemedContainer>
    </>
  );
}
