import React from 'react';
import { Container } from '@mui/material';
import { breadLogicUtils_getRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import useE2eStore from 'src/e2e-store';

import { e2eThankYou_init } from './bread-logic/bread-thank-you-logic';
import BreadThankYouComponent from './bread-thank-you-component';

export default function BreadE2eThankYouPage() {
  const routingPackage: E2eLogicObjectsWithAasoParams = breadLogicUtils_getRoutingLogicObjects();
  e2eThankYou_init(routingPackage);
  const typd = useE2eStore((state) => state.thank_you_page_details);
  // I think I can remove this now that I'm saving the path once the user saved the form
  // selectAppLogic_functionsAndVals(routingPackage);
  return <Container maxWidth="sm">{typd && <BreadThankYouComponent typd={typd} />}</Container>;
}
