import { FieldDetailI } from 'src/interfaces/application.interfaces';

const breadFieldDisclosures = Object.freeze<Record<string, { body: string }>>({
  gross_monthly_income: {
    body: `
      <p>Alimony, child support or separate maintenance income need not to be included if you do not wish to have it considered as a basis for repaying this obligation.</p>
      <b>Married WI Residents only:</b> <p>If you are applying for an individual account and your spouse also is a WI resident, combine your and your spouse's financial information</p>.
    `,
  },
  mobile_phone: {
    body: `By providing a mobile number or a number later converted to a mobile number, you agree that Comenity Bank, Comenity Capital Bank and their service providers may contact you regarding your accounts at that number, including an autodialer, recorded or artificial voice, and text. Message and data rates may apply.`,
  },
  estimated_purchase_amount: {
    body: 'While the estimated purchase amount is requested, please note that some payment options do not take this into account for their decision. This information is not required by or provided to Comenity Capital Bank.',
  },
});

/**
 * This function is exclusively used for the bread E2E integration
 * @param fields
 */
export const extendFieldsForBread = (fields: FieldDetailI[]) => {
  const breadFieldsWithDisclosures = Object.keys(breadFieldDisclosures);

  return fields.map((field) => {
    if (field.default_details.name && breadFieldsWithDisclosures.includes(field.default_details.name)) {
      return {
        ...field,
        default_details: {
          ...field.default_details,
          msg: null,
          acc_msg: {
            body: breadFieldDisclosures[field.default_details.name].body,
            title: '',
          },
          hide_element_disc: true,
        },
        final_details: {
          ...field.final_details,
          msg: null,
          acc_msg: {
            body: breadFieldDisclosures[field.default_details.name].body,
            title: '',
          },
          hide_element_disc: true,
        },
      };
    }

    return messageToAcordion(field);
  });
};

const messageToAcordion = (field: FieldDetailI) => {
  if (field.default_details?.msg === null || field.final_details?.msg === null) {
    return field;
  }

  return {
    ...field,
    default_details: {
      ...field.default_details,
      acc_msg: {
        body: field.default_details.msg,
        title: '',
      },
      msg: null,
      hide_element_disc: true,
    },
    final_details: {
      ...field.final_details,
      acc_msg: {
        body: field.final_details?.msg,
        title: '',
      },
      msg: null,
      hide_element_disc: true,
    },
  };
};

export const transformStaticDisclosuresToAccordion = (fields: FieldDetailI[]) => {
  return fields.map((field) => {
    return messageToAcordion(field);
  });
};
