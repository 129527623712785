import type { Location } from '@remix-run/router';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { AuthContextType } from 'src/contexts/auth/jwt-context';
import { breadLogic_getValidE2eLenders } from 'src/e2e-redesign/business-logic/bread-logic/bread-business-logic';
import {
  breadDiscLogic_chunkDisclosures,
  breadDiscLogic_hasAcceptedAllDisclosures,
} from 'src/e2e-redesign/business-logic/bread-logic/bread-disclosure-logic';
import { breadSubLogic_kickOffNoDisplay } from 'src/e2e-redesign/business-logic/bread-logic/bread-submission-logic';
import {
  ApprovalUrlParamsI,
  E2eRoutingArgsI,
  E2eRoutingLogicI,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { BreadCFARoutes, E2eApprovalRoutes, E2ERoutes, ErrorRoutes } from 'src/enums/routes.enums';
import { AASOContextType } from 'src/hooks/use-aaso';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { ApplicationSettingsI } from 'src/interfaces/application-settings.interfaces';
import { E2eAppConfigurationsI, E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { BREAD_LENDER_ID } from 'src/utils/bread-e2e-constants';

const approvalPath = '/applicant-approve-offers/';
const getPublicE2ePath = (store_name: string, store_uuid: string, e2e_form_id: string, route: BreadCFARoutes | E2ERoutes) => {
  const storeName = encodeURIComponent(store_name);
  const storeUuid = encodeURIComponent(store_uuid);
  const e2eFormId = encodeURIComponent(e2e_form_id);
  /// '/:store_name/:store_uuid/:e2e_form_id/<whatever>',
  return `/${storeName}/${storeUuid}/${e2eFormId}/${route}`;
};

const getApprovalPath = (params: ApprovalUrlParamsI, route: E2eApprovalRoutes) => {
  const { fp_unique_id, aaso_id, form_id, store_name, e2e_form_id, store_uuid } = params;
  if (!fp_unique_id || !aaso_id || !form_id || !store_name || !e2e_form_id || !store_uuid)
    throw new Error('Missing required params');
  const fpUniqueId = encodeURIComponent(fp_unique_id);
  const storeName = encodeURIComponent(store_name);
  const storeUuid = encodeURIComponent(store_uuid);
  const aasoId = encodeURIComponent(aaso_id);
  const e2eFormId = encodeURIComponent(e2e_form_id);
  const formId = encodeURIComponent(form_id);

  /// '/:store_name/:store_uuid/:e2e_form_id/<whatever>',
  return `${approvalPath}${storeName}/${fpUniqueId}/${aasoId}/${storeUuid}/${e2eFormId}/${formId}/${route}`;
};
const getFullE2ePath = (
  store_name: string,
  store_uuid: string,
  aaso_id: string,
  e2e_form_id: string,
  route: BreadCFARoutes | string,
) => {
  const storeName = encodeURIComponent(store_name);
  const storeUuid = encodeURIComponent(store_uuid);
  const aasoId = encodeURIComponent(aaso_id);
  const e2eFormId = encodeURIComponent(e2e_form_id);
  // '/:store_name/:store_uuid/:e2e_form_id/:aaso_id/<whatever>'
  return `/${storeName}/${storeUuid}/${e2eFormId}/${aasoId}/${route}`;
};

export const breadHandleE2eUnauthenticatedRoutingLogic = (
  obj: E2eRoutingLogicI,
  args: E2eRoutingArgsI,
  newAaso?: AASO,
) => {
  const { currentStage } = args;
  const logicObjectsExist = verifyPrimaryFunctionsAndObjectsExist(obj);
  const appConfigurationObject = obj.e2eStore.app_configurations;

  // console.log(e2eStore);
  // console.log('route', logicObjectsExist, e2eStoreLogicObjectsExist);
  if (logicObjectsExist && appConfigurationObject) {
    switch (currentStage) {
      case ApplicationStage.STARTED:
        breadHandleUnauthenticatedWelcomeRouting(obj, args);
        break;
    }
  }
  // navigate(AuthRoutes.Login);
};

export const breadHandleCreateAccountRoutingLogic = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI) => {
  const { e2eStore, navigate } = obj;
  const aaso = e2eStore.aaso;
  if (aaso && aaso.e2e_form_id) {
    navigate(`${getPublicE2ePath(aaso.store_name, aaso.store_uuid, aaso.e2e_form_id, BreadCFARoutes.CreateAccount)}`);
  }
};

export const breadRouting_routeTo404 = (navigate: NavigateFunction) => {
  navigate('/' + ErrorRoutes.NotFound);
};
export const breadHandleE2eRoutingLogic = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore } = obj;
  const { currentStage } = args;
  const logicObjectsExist = verifyPrimaryFunctionsAndObjectsExist(obj);
  const e2eStoreLogicObjectsExist = verifyE2eStoreLogicObjectsExist(e2eStore);

  if (logicObjectsExist && e2eStoreLogicObjectsExist) {
    switch (currentStage) {
      case ApplicationStage.STARTED:
        breadHandleWelcomeRouting(obj, args, newAaso);
        break;
      case ApplicationStage.FLOW_STARTED:
        console.log('Flow started ROUTE');
        breadHandleGetStartedRouting(obj, args, newAaso);
        break;
      case ApplicationStage.SELECTED_PATH:
        console.log('Select ROUTE');
        breadHandlePathSelectedGetStarted(obj, args, newAaso);
        break;
      case ApplicationStage.FORM_STARTED:
        breadHandleApplicationRouting(obj, args, newAaso);
        break;
      case ApplicationStage.DISCLOSURE_LENDERS:
        breadHandleLenderDisclosureRouting(obj, args, newAaso);
        break;
      case ApplicationStage.FORM_SUBMITTED:
        breadHandleE2eProviderResponseRouting(obj, args, newAaso);
        break;
      case ApplicationStage.INITIAL_SELECTION:
      case ApplicationStage.SUBMITTED_TO_LENDER:
        breadHandleE2eProviderResponseRouting(obj, args, newAaso);
        break;
      case ApplicationStage.LENDER_SELECTED:
        breadHandleE2eUserCompletedInitialSelectionRouting(obj, args);
    }
  }
  // navigate(AuthRoutes.Login);
};

export const breadHandleE2eUserCompletedInitialSelectionRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI) => {
  const { navigate } = obj;
  navigate(`/${E2ERoutes.Dashboard}`, { replace: true });
};

export const breadHandleE2eApplicantApprovedNewOfferRouting = (
  navigate: NavigateFunction,
  params: ApprovalUrlParamsI,
) => {
  const path = getApprovalPath(params, E2eApprovalRoutes.ThankYou);
  navigate(path, { replace: true });
};
export const breadHandleE2eProviderResponseRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate } = obj;
  const aaso = e2eStore.aaso;
  const appSettings: ApplicationSettingsI = e2eStore.application_settings!;
  const e2eForms = appSettings?.e2e?.e2e_forms;
  if (e2eStore.application_settings) {
    if (newAaso && newAaso._id && newAaso.e2e_form_id) {
      // user is selecting to continue an existing application
      navigate(
        getFullE2ePath(
          newAaso.store_name,
          newAaso.store_uuid,
          newAaso._id,
          newAaso.e2e_form_id,
          BreadCFARoutes.ProviderResponses,
        ),
        { replace: true }, // We don't want the user going back
      );
    } else {
      if (aaso && aaso._id && aaso.e2e_form_id) {
        navigate(
          getFullE2ePath(
            aaso.store_name,
            aaso.store_uuid,
            aaso._id,
            aaso.e2e_form_id,
            BreadCFARoutes.ProviderResponses,
          ),
          { replace: true }, // We don't want the user going back
        );
      }
    }
  }
};
export const breadHandleE2eFormSubmittedRoutingLogic = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI) => {
  const { e2eStore, navigate } = obj;
  const aaso = obj.aasoContext?.aaso;

  if (aaso) {
    const { store_uuid, store_name, _id, e2e_form_id } = aaso;
    console.log('handle form submit routing');
    if (_id && e2e_form_id) {
      const appConfig = obj.e2eStore.app_configurations;
      if (appConfig) {
        const currentPath = appConfig?.current_path;
        // grab all auto_submits and not
        if (currentPath) {
          // TODO - need to change this to && instead of ||
          // includeAutoSubmit is it handle the new cfa_config object
          const includeAutoSubmit = 'display_offers' in currentPath;
          if (includeAutoSubmit) {
            // it's the new cfa
            const e2eLenders = breadLogic_getValidE2eLenders(
              appConfig.lenders,
              appConfig.current_path,
              includeAutoSubmit,
            );
            if (e2eLenders.length > 0 && currentPath?.display_offers) {
              console.log('go to provider response');
              navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.ProviderResponses), {
                replace: true,
              });
            } else {
              // TODO - add toggle auto-submit here
              const hasAutoSubmits = currentPath.lender_details.some((ld) => ld.auto_submit);
              console.log({ hasAutoSubmits });
              if (hasAutoSubmits) {
                breadSubLogic_kickOffNoDisplay({ aaso_id: _id, e2e_form_id, store_uuid });
              }
              const path = getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.ThankYou);
              navigate(path, { replace: true });
            }
          } else {
            // old object
            const e2eLenders = breadLogic_getValidE2eLenders(appConfig.lenders, appConfig.current_path);
            if (e2eLenders.length > 0) {
              navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.ProviderResponses), {
                replace: true,
              });
            } else {
              const path = getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.ThankYou);
              navigate(path, { replace: true });
            }
          }
        }
      }
    }
  }
};
export const breadHandleLenderDisclosureRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate } = obj;
  const appSettings: ApplicationSettingsI = e2eStore.application_settings!;
  const aaso = obj.aasoContext?.aaso;
  if (aaso) {
    const e2eForm = appSettings.e2e?.e2e_forms.find((form) => form.id === aaso.e2e_form_id);
    const e2ePath = e2eForm?.paths.find((p) => p.id === aaso.current_path_id);
    const chunked = breadDiscLogic_chunkDisclosures(e2eStore.disclosures, e2eStore.lenders, e2ePath);
    const allDiscsAccepted = breadDiscLogic_hasAcceptedAllDisclosures(chunked, aaso.disclosures);
    const { store_uuid, store_name, _id, e2e_form_id } = aaso;
    if (_id && e2e_form_id) {
      if (allDiscsAccepted) {
        navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.DisclosureSubmit), {
          replace: true,
        });
      } else {
        navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.ProviderDisclosures));
      }
    }
  }
};

export const breadHandleCancelApplicationRouting = (obj: E2eRoutingLogicI) => {
  const { e2eStore, navigate, location } = obj;
  const aaso = obj.aasoContext?.aaso;

  if (aaso) {
    const { store_uuid, store_name, e2e_form_id } = aaso;
    if (e2e_form_id) {
      // Get the mLink parameter from the current URL if it exists
      const currentSearchParams = new URLSearchParams(location.search);
      const mLink = currentSearchParams.get('mLink');

      const path = getPublicE2ePath(store_name, store_uuid, e2e_form_id, E2ERoutes.Welcome);
      navigate(mLink ? `${path}?mLink=${mLink}` : path);
    }
  }
};

const breadHandleGetStartedRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate } = obj;
  const aaso = obj.aasoContext?.aaso;
  if (aaso) {
    const { store_uuid, store_name, _id, e2e_form_id } = aaso;
    if (_id && e2e_form_id) {
      navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.Application));
    }
  }
};

const breadHandleApplicationRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate } = obj;
  const aaso = obj.aasoContext?.aaso;
  if (aaso) {
    const { store_uuid, store_name, _id, e2e_form_id } = aaso;
    if (_id && e2e_form_id) {
      navigate(getFullE2ePath(store_name, store_uuid, _id, e2e_form_id, BreadCFARoutes.Application));
    }
  }
};

const breadHandlePathSelectedGetStarted = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate, aasoContext } = obj;
  const aaso = aasoContext.aaso;
  if (e2eStore.application_settings) {
    if (aaso && aaso._id && aaso.e2e_form_id && aaso.current_path_id) {
      navigate(getFullE2ePath(aaso.store_name, aaso.store_uuid, aaso._id, aaso.e2e_form_id, BreadCFARoutes.GetStarted));
    }
  }
};

export const breadHandleE2eLoginLogicRoute = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, e2eFormId?: string) => {
  /**
   * Modal logic
   */
  const { navigate } = obj;
  const aaso = args.aaso;
  const cont = args.continue;
  const continueRoute = args.continueRoute;

  if (aaso && continueRoute && e2eFormId) {
    const { store_name, store_uuid, _id } = aaso;
    if (store_name && store_uuid && _id) {
      if (cont) {
        // path += `${store_name}/${store_uuid}/${_id}/${e2eFormId}/${continueRoute}`;
        navigate(getFullE2ePath(store_name, store_uuid, _id, e2eFormId, continueRoute));
        return;
      } else {
        // path += `${aaso.store_name}/${aaso.store_uuid}/${e2eFormId}/${aaso._id}/${continueRoute.split('/').pop()}`;
        const newRoute = continueRoute.split('/').pop();
        if (newRoute) {
          navigate(getFullE2ePath(store_name, store_uuid, _id, e2eFormId, newRoute));
        }
      }
    }
  } else {
    // catch all - send to dashboard
    navigate(`/${E2ERoutes.Dashboard}`, { replace: true });
  }
};
const breadHandleWelcomeRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI, newAaso?: AASO) => {
  const { e2eStore, navigate, aasoContext } = obj;
  const aaso = aasoContext.aaso;
  console.log('WELCOME ROUTE');
  if (e2eStore.application_settings) {
    const lenderLineup = e2eStore.app_configurations?.lender_ids;
    if (lenderLineup && lenderLineup.includes(BREAD_LENDER_ID)) {
      // TODO - switch to user Bread ID
      /**
       * NOTE:
       * - this is a temporary solution for the bread CFA. This directs the user to use
       * the BreadCFARoutes
       */
      if (aaso && aaso._id && aaso.e2e_form_id && aaso.current_path_id) {
        // R:TODO E2E P0 - refactor
        // THIS is a multi-path and user has selected, so send them to GetStarted
        navigate(
          getFullE2ePath(aaso.store_name, aaso.store_uuid, aaso._id, aaso.e2e_form_id, BreadCFARoutes.GetStarted),
        );
      } else {
        const appConfig: E2eAppConfigurationsI = e2eStore.app_configurations!;
        const route = appConfig.multi_paths_exist ? BreadCFARoutes.SelectApplicationType : BreadCFARoutes.GetStarted;
        if (newAaso) {
          // user is selecting to continue an existing application
          if (newAaso._id && newAaso.e2e_form_id) {
            // TODO - continuing a bread cfa app is broken and needs to be fixed
            navigate(getFullE2ePath(newAaso.store_name, newAaso.store_uuid, newAaso._id, newAaso.e2e_form_id, route), {
              replace: true,
            });
          }
        } else {
          navigate(route);
        }
      }
    } else {
      if (aaso && aaso._id && aaso.e2e_form_id && aaso.current_path_id) {
        // R:TODO E2E P0 - refactor
        // THIS is a multi-path and user has selected, so send them to GetStarted
        navigate(
          getFullE2ePath(aaso.store_name, aaso.store_uuid, aaso._id, aaso.e2e_form_id, BreadCFARoutes.GetStarted),
        );
      } else {
        const appConfig: E2eAppConfigurationsI = e2eStore.app_configurations!;
        const route = appConfig.multi_paths_exist ? BreadCFARoutes.SelectApplicationType : BreadCFARoutes.GetStarted;
        if (newAaso) {
          // user is selecting to continue an existing application
          if (newAaso._id && newAaso.e2e_form_id) {
            navigate(getFullE2ePath(newAaso.store_name, newAaso.store_uuid, newAaso._id, newAaso.e2e_form_id, route), {
              replace: true,
            });
          }
        } else {
          navigate(route);
        }
      }
    }
  }
};

const breadHandleUnauthenticatedWelcomeRouting = (obj: E2eRoutingLogicI, args: E2eRoutingArgsI) => {
  const { e2eStore, navigate } = obj;
  const appConfig: E2eAppConfigurationsI = e2eStore.app_configurations!;
  if (appConfig) {
    const route = appConfig.multi_paths_exist ? BreadCFARoutes.SelectApplicationType : BreadCFARoutes.GetStarted;
    return navigate(route);
  }
  throw new Error('Failed to route unauthorized user to welcome routing');
};

const verifyPrimaryFunctionsAndObjectsExist = (obj: E2eRoutingLogicI) => {
  const { auth, e2eStore, aasoContext, navigate, location } = obj;
  if (!auth || !e2eStore || !aasoContext || !navigate || !location) {
    return false;
  }
  return true;
};
const verifyE2eStoreLogicObjectsExist = (e2eStore: E2eGlobalStore) => {
  if (
    e2eStore.application_settings &&
    e2eStore.lenders &&
    e2eStore.store_settings &&
    e2eStore.store_details &&
    e2eStore.outside_form_details
  ) {
    return true;
  }
  return false;
};
