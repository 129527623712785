import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { Seo } from 'src/components/seo';
import { E2EChunkedLenderDisclosuresI } from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import DisclosureHeading from 'src/e2e-redesign/components/DisclosureHaeding';
import { providerDiscLogic_childFuncsAndVals } from 'src/e2e-redesign/views-bread/BreadProviderDisclosures/bread-logic/bread-provider-disclosures-logic';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import { BREAD_LENDER_ID } from 'src/utils/bread-e2e-constants';
import * as Yup from 'yup';

import PageIntro from '../../../components/PageIntro';
import SingleLogo from '../../../components/SingleLogo';
import ThemedContainer from '../../../components/ThemedContainer';
import styles from '../BreadProviderDisclosures.module.css';

export interface SingleProviderDisclosuresProps {
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure | undefined;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

export default function BreadProviderSingleDisclosure(props: SingleProviderDisclosuresProps) {
  const { selectedDisclosures, routingPackage } = props;
  const fnv = providerDiscLogic_childFuncsAndVals(routingPackage, selectedDisclosures);
  const { handleSingleDisclosureAccept, handleGoBackDisclosures, handleCancelApplication } = fnv.fn;
  const { disclosures, showGoBackButton } = fnv.v;
  const theme = useTheme();
  if (!selectedDisclosures) return <></>;
  const logo = {
    src: disclosures?.logo || '',
    alt: disclosures?.name || '',
  };

  const initialValues = {
    agreeTerms: false,
  };

  const validationSchema = Yup.object().shape({
    agreeTerms: disclosures?.opt_in_marketing
      ? Yup.boolean()
      : Yup.boolean().oneOf([true], 'You must agree to the terms.'),
  });

  const isBread = disclosures?.lender_id == BREAD_LENDER_ID;

  return (
    <>
      <Seo title="Account Terms & Conditions" />

      <ThemedContainer>
        <PageIntro title="Account Terms & Conditions" />
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <SingleLogo image={logo} />
        </Box>
        {disclosures?.document_additional_info && (
          <Box key={disclosures?.document_additional_info_header} mb={6}>
            <DisclosureHeading>{disclosures?.document_additional_info_header}</DisclosureHeading>
            <Box
              sx={{
                border: '1px solid #E0E8F5',
                borderRadius: '15px',
                padding: '10px',
                maxHeight: { xs: '450px', md: '300px' },
                overflowY: 'scroll',
                marginBottom: '30px',
                '&::-webkit-scrollbar': {
                  width: '12px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#ffffff',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#E0E8F5',
                  borderRadius: '15px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#B0C4DE',
                },
              }}
            >
              {disclosures?.dynamic_link ? (
                <iframe
                  className={styles.dialogContent}
                  src={disclosures?.dynamic_link}
                  title={disclosures.name}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                />
              ) : (
                <div
                  className={styles.dialogContent}
                  dangerouslySetInnerHTML={{
                    __html: disclosures?.document_additional_info ? disclosures.document_additional_info : '',
                  }}
                />
              )}
            </Box>
          </Box>
        )}

        {
          /**
          TODO BREAD update print a copy with link
        **/ isBread ? (
            <>
              <Typography paragraph variant="body2" color={theme.palette.text.secondary}>
                You must read the disclosures presented in the{' '}
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://d.comenity.net/legaldocs/petland/legal-documents/cca"
                  sx={{ fontWeight: 'bold' }}
                >
                  Electronic Consent
                </Link>{' '}
                section of the Terms and Conditions box below prior to checking the consent box. Please also read the
                Credit Card Agreement, Privacy Statement and other information presented in the Terms and Conditions box
                prior to submitting this application and{' '}
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://d.comenity.net/legaldocs/petland/legal-documents/cca"
                >
                  print a copy
                </Link>{' '}
                for your records.
              </Typography>
              <Typography paragraph variant="body1">
                Consent to Financial Terms of the Account.
              </Typography>
            </>
          ) : (
            <Typography paragraph variant="body1">
              By clicking “I AGREE” below you acknowledge the following:
            </Typography>
          )
        }

        {isBread && (
          <iframe
            src="https://d.comenity.net/legaldocs/petland/legal-documents/cca"
            title="electronic-consent"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              minHeight: '50vh',
              borderRadius: '15px',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#E0E8F5',
              marginBottom: '20px',
            }}
          />
        )}
        {!isBread && (
          <Box
            sx={{
              border: '1px solid #E0E8F5',
              borderRadius: '15px',
              padding: '10px',
              // height: { xs: '450px', md: '300px' },
              maxHeight: { xs: '450px', md: '300px' },
              overflowY: 'scroll',
              marginBottom: '20px',
              // TODO: Add themed scrollbar to a global css file if possible
              '&::-webkit-scrollbar': {
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#ffffff',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#E0E8F5',
                borderRadius: '15px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0C4DE',
              },
            }}
          >
            {disclosures?.dynamic_link && (
              <iframe
                className={styles.dialogContent}
                src={disclosures?.dynamic_link}
                title={disclosures.name}
                style={{ width: '100%', height: '100%', border: 'none' }}
              />
            )}

            {!disclosures?.dynamic_link && (
              <div
                className={styles.dialogContent}
                dangerouslySetInnerHTML={{ __html: disclosures?.document ? disclosures.document : '' }}
              />
            )}
          </Box>
        )}
        {isBread && (
          <Typography paragraph variant="body2" color={theme.palette.text.secondary}>
            By selecting "I Consent", and "Submit" I agree that I am applying for credit with Comenity Capital Bank,
            agreeing to Comenity's Terms and Conditions and{' '}
            <Link
              href="https://d.comenity.net/ac/petland/public/privacy/financial"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              Financial Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href="https://d.comenity.net/ac/petland/public/privacy/online"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              Online Privacy Policy
            </Link>
            , consenting to electronically receiving documents and am electronically signing this
            application/solicitation. I understand that if approved for the Petland Credit Card, the credit limit may
            not cover my full purchase amount and additional payment or financing options may be available.
          </Typography>
        )}
        <Formik
          key={disclosures?._id}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            // TODO: Handle form submission
            handleSingleDisclosureAccept(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Box mb={2}>
                <Field
                  component={CheckboxWithLabel}
                  name="agreeTerms"
                  type="checkbox"
                  Label={{
                    label: disclosures?.accept_text
                      ? disclosures.accept_text
                      : disclosures?.opt_in_marketing
                      ? 'I opt in marketing communications'
                      : 'I agree to the terms above.',
                  }}
                />
                {/* <span style={{ color: '#677292' }}>{disclosures?.accept_text || 'I agree to the above terms.'}</span> */}
                <ErrorMessage
                  name="agreeTerms"
                  component="div"
                  render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                />
              </Box>
              {/* TODO: Make stack on mobile */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { md: 'flex-start' },
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  size="large"
                  type="submit"
                  disabled={isSubmitting || (!disclosures?.opt_in_marketing && !values.agreeTerms)}
                >
                  {isBread ? 'Submit' : 'I Agree'}
                </Button>
                {/* R:TODO E2E P0 - if first disclosures do not go back */}
                {showGoBackButton && (
                  <Button
                    variant="text"
                    sx={{ color: theme.palette.text.primary, textDecoration: 'underline' }}
                    onClick={handleGoBackDisclosures}
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Box sx={{ mt: 2, textAlign: { xs: 'center', md: 'left' } }}>
                <Button variant="text" sx={{ textDecoration: 'none' }} onClick={handleCancelApplication}>
                  Cancel Application
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ThemedContainer>
    </>
  );
}
