import React, { useEffect } from 'react';
import { breadLogicUtils_getBaseRoutingLogicObjects } from 'src/e2e-redesign/business-logic/bread-logic/bread-logic-utils';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { createAccountLogic_funcAndVals } from 'src/e2e-redesign/views-bread/BreadCreateAccountNewCustomer/bread-logic/bread-create-account-logic';
import { DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';

import ThemedDialog from '../../components/ThemedDialog/ThemedDialog';

interface FpDisclosureDialogProps {
  open: boolean;
  onClose: () => void;
  dialogType: string | null;
}

// const dialogContents = {
//   terms: termsContent,
//   customerNotice: customerNoticeContent,
//   privacyPolicy: privacyPolicyContent,
// };

const FpDisclosureDialog: React.FC<FpDisclosureDialogProps> = ({ open, onClose, dialogType }) => {
  const routingPackage: E2eLogicObjectsWithParams = breadLogicUtils_getBaseRoutingLogicObjects();
  const fnv = createAccountLogic_funcAndVals(routingPackage);
  const { sectionValues } = fnv.v;
  const disclosures = routingPackage.e2eStore.disclosures;
  const fp_privacy = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.fp_privacy);
  const fp_tandc = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.fp_myfp_tc);
  const fp_cust_notice = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.fp_customer_notice);
  sectionValues.form.fp_privacy.diclosure_id = fp_privacy?._id ?? sectionValues.form.fp_privacy.diclosure_id;
  sectionValues.form.fp_myfp_tc.diclosure_id = fp_tandc?._id ?? sectionValues.form.fp_myfp_tc.diclosure_id;
  sectionValues.form.fp_customer_notice.diclosure_id =
    fp_cust_notice?._id ?? sectionValues.form.fp_customer_notice.diclosure_id;

  const [dialogContents, setDialogContents] = React.useState<{
    terms: string;
    customerNotice: string;
    privacyPolicy: string;
  }>({
    terms: 'Loading...',
    customerNotice: 'Loading...',
    privacyPolicy: 'Loading...',
  });
  useEffect(() => {
    setDialogContents({
      terms: fp_tandc?.document || '',
      customerNotice: fp_cust_notice?.document || '',
      privacyPolicy: fp_privacy?.document || '',
    });
  }, [fp_privacy, fp_cust_notice, fp_tandc]);

  const getDialogContent = (): string => {
    if (!dialogType) return '';
    return dialogContents[dialogType as keyof typeof dialogContents] || '';
  };

  return <ThemedDialog open={open} onClose={onClose} content={getDialogContent()} />;
};

export default FpDisclosureDialog;
